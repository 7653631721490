import { Pipe, PipeTransform } from '@angular/core';
import dayjs from 'dayjs';

@Pipe({
  name: 'timeDuration',
  standalone: true
})
export class TimeDurationPipe implements PipeTransform {

  transform(value: dayjs.Dayjs | null): string {
    if (!value)
      return '';
    
    const hours = value.get('hours');
    const minutes = value.get('minutes');

    return `${hours ? hours + 'hr ' : ''}${minutes ? minutes + 'min' : ''}`.trim() || '0hr';
  }

}
