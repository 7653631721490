import { Injectable, inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService,  } from '../services/auth.service';

@Injectable()
  export class AuthLoginGuardService {

  constructor(
    private auth: AuthService,
    public router: Router,
  ) { }

  canActivate(): boolean {
    if (!this.auth.isLoggedIn()) {
      return true;
    } 
    
    this.router.navigate(['']);
    return false
  }
}


export const authLoginGuard: CanActivateFn = (route, state) => {
  return inject(AuthLoginGuardService).canActivate();
};
