<div *ngIf='!isHidden()' class="{{'chat-messages' + (getIsExpanded() ? ' expanded' : '') + getPositionClass()}}">

  <div class='row no-gutters chat-messages-header'>
    <div class="col-auto profile-image">
      <div *ngIf="isDefaultPicture(getChatRoom().Participants[0].ProfileImageUrl)" class="square op-avatar"></div>
      <img *ngIf='!isDefaultPicture(getChatRoom().Participants[0].ProfileImageUrl)' src="{{getChatRoom().Participants[0].ProfileImageUrl}}">
    </div>
    <div class="col px-2 align-self-center title">
      <span>{{getChatRoom().Participants[0].ContactName}}</span>
    </div>
    <div class="col-auto px-2 align-self-center expand-icon">
      <div *ngIf="!expanded" class="arrows-out" (click)="toogleExpanded()">
        <div class="left-arrow-out"></div>
        <div class="right-arrow-out"></div>
      </div>
      <div *ngIf="expanded" class="arrows-in" (click)="toogleExpanded()">
        <div class="left-arrow-in"></div>
        <div class="right-arrow-in"></div>
      </div>
    </div>
    <div class="col-auto px-2 align-self-center close-icon" (click)="closeChatMessages()">X</div>
  </div>

  <div #chatHistory class='chat-messages-list'>
    <div *ngFor='let msg of getMessages()' class='row no-gutters pt-3 chat-message'>
      <div class="col-auto px-2 chat-participant-image">
        <div *ngIf="getSenderProfilePicture(msg)" class="square op-avatar"></div>
        <img *ngIf='!getSenderProfilePicture(msg)' src="{{getChatParticipant(msg.message.pn_other.userId).ProfileImageUrl}}">
      </div>
      <div class="col px-1">
        <div>
          <span class="chat-participant-name">{{getSenderContactName(msg)}}</span>
          <span class="chat-message-timestamp"> {{msg.message.pn_other.timestamp  | messageTime}}</span>
        </div>
        <div class="chat-message-text">{{msg.message.pn_other.message}}</div>
      </div>
    </div>
  </div>

  <div class="publish-new-message">
    <textarea type="text" placeholder="Write a message..." [(ngModel)]="messageText" (keydown)="onSendMessageKeyDown($event)"></textarea>
    <div class="row no-gutters justify-content-end send-message-container">
      <div class="col-auto align-self-center px-2 send-button" (click)="sendMessage()">
        <span class="send-button-label pr-2">Send</span>
        <span class="send-button-icon material-icons">send</span>
      </div>
    </div>
  </div>

</div>
