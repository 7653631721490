import { Dayjs } from "dayjs";

export interface DatesFilterRequest {
    DateRanges: DateRange[];
    WeekDayPattern: number[];
    ExcludeHolidays: boolean;
    ExcludeHolidaysRegion: string;
  }
  
  export interface DateRange {
    StartDate: Date | Dayjs | string;
    EndDate: Date | Dayjs | string;
  }