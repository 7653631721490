import { AutofillMonitor } from '@angular/cdk/text-field';
import { Directive, ElementRef, OnDestroy, OnInit, Optional, Self } from '@angular/core';
import {
  DefaultValueAccessor,
  NgControl,
  RequiredValidator,
  Validators,
} from '@angular/forms';
import { Subscription, timer } from 'rxjs';

/**
 * This directive monkeypatches a RequiredValidator directive instance to accept autofilled
 * inputs as valid despite reporting an empty value. Ideally this should be supported directly
 * by the DefaultValueAccessor and Validators.required() should be able to take this into account.
 */
@Directive({
  // the selector used for DefaultValueAccessor
  selector: 'input:not([type=checkbox])[formControlName],textarea[formControlName],input:not([type=checkbox])[formControl],textarea[formControl],input:not([type=checkbox])[ngModel],textarea[ngModel],[ngDefaultControl]',
})
export class AutofillValueAccessor implements OnInit, OnDestroy {
  autofilled = false;
  subscription: Subscription;
  autofillContent = `"${String.fromCharCode(0xFEFF)}"`;

  constructor(
    private _autofillMonitor: AutofillMonitor,
    @Self() private _valueAccessor: DefaultValueAccessor,
    @Self() private _elementRef: ElementRef,
    @Self() private control: NgControl,
    @Optional() @Self() private validators: Validators,
    @Optional() @Self() private requiredValidator: RequiredValidator,
  ) {

  }

  ngOnInit() {

    this.subscription = timer(0, 50).subscribe(val => {
      if (this.checkAutofill(this._elementRef.nativeElement)) {
        // this._elementRef.nativeElement.focus();
        // this._elementRef.nativeElement.blur();
        this.subscription.unsubscribe();
      }
    });

  }

  checkAutofill(input) {
    if (!input.value) {
      const style = window.getComputedStyle(input);
      if (style.content !== this.autofillContent) {
        return false;
      }
    }

    return true;
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
