import { Directive, ElementRef, OnInit } from '@angular/core';
import $ from 'jquery';

@Directive({
  selector: '[pVisibility]'
})
export class PasswordVisibilityDirective implements OnInit {
  state = false;
  el: any;

  constructor(
    private element: ElementRef,
  ) {
  }

  ngOnInit(): void {
    this.el = $(this.element.nativeElement);
    this.el.addClass('with-toggle');
    this.el.append(
      `<i class="m-icon va-m password-toggle">${this.state ? 'visibility' : 'visibility_off'}</i>`
    );
    this.el.find('i.password-toggle').click(this.toggleVisibility);
  }

  toggleVisibility = () => {
    this.state = !this.state;
    this.el.find('i.password-toggle').html(this.state ? 'visibility' : 'visibility_off');
    this.el.find('input').prop('type', this.state ? 'text' : 'password');
  };


}
