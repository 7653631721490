import { ClientContactSummary, ClientSummary } from '.';

export interface AppTitle {
  title: string;
  subtitle?: string;
  showStepsCount?: boolean;
  currentStep?: number;
  totalSteps?: number;
  userInfo?: ClientSummary;
}
