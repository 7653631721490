import { Component, ContentChildren, EventEmitter, Input, OnInit, Output, QueryList } from '@angular/core';
import { ExpandableListItemComponent } from './expandable-list-item.component';

@Component({
  selector: 'app-expandable-list',
  templateUrl: './expandable-list.component.html',
  styleUrls: ['./expandable-list.component.scss']
})
export class ExpandableListComponent implements OnInit {
  @Input('listTitle') title: string;
  @Input() subtitle: string;
  @Input() topHeader: boolean = true;
  @Input() expanded: boolean = false;
  @Input() collapsible: boolean = false;
  @Input() showLoader: boolean = false;
  @Output() onExpandAll: EventEmitter<any> = new EventEmitter<any>();  

  @ContentChildren(ExpandableListItemComponent) items: QueryList<ExpandableListItemComponent>;
  
  constructor() { }

  ngOnInit() {
  }

  toogleContentVisibility() {
    this.expanded = !this.expanded;

    if (this.collapsible) {
      return;
    }

    let allItemsExpanded: boolean = true;

    this.items.toArray().forEach(item => {
      if (item.expandable && !item.expanded) {
        allItemsExpanded = false;
      }
    });

    if (!allItemsExpanded) {
      this.onExpandAll.emit(true);
    } else {
      this.onExpandAll.emit(false);
    }

    this.items.toArray().forEach(item => { 
      if(item.expandable) {
        item.expanded = !allItemsExpanded;
      }
    });
  }

}
