import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-steps-progress',
  templateUrl: './steps-progress.component.html',
  styleUrls: ['./steps-progress.component.scss']
})
export class StepsProgressComponent implements OnInit {
  @Input() steps: number = 3;
  @Input() currentStep: number = 1;
  @Input() color: string = 'rgb(12, 134, 179)';

  constructor() { }

  ngOnInit() {
  }

}
