import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { DetailedTagCategory, JobRole, JobRoleGroup } from '@app/models/jobrole.model';
import { BehaviorSubject, Observable, filter, map, tap } from 'rxjs';
import { TagTree } from '@app/models';
import { JobRoleTree } from '@app/modules/addjobs/ui-models/job-role-ui';

export interface JobRoleSetupData {
  RoleGroups: JobRoleGroup[];
  JobRoles: JobRoleTree;
  TagCategories: DetailedTagCategory[];
  ClientJobRolePermissions: string[];
}

export class JobRoleConfig {
  AllJobRoles: JobRoleTree;
  ClientJobRoles: JobRoleTree;
  RoleGroups: JobRoleGroup[];
  TagCategories: DetailedTagCategory[];

  getJobRoleById(roleId: string): JobRole {
    let jobRole =  this.AllJobRoles.findChild(roleId)

    return jobRole;
  }

  getJobRoleTreeSimple(): JobRoleTree {
    let jobRoleTree = JobRoleTree.create();

    jobRoleTree.setItems(this.ClientJobRoles.filter(jr => !jr.IsQuickSelect));

    return jobRoleTree;
  }

  getJobRoleTreeWithTopRoles(): JobRoleTree {
    let jobRoleTree = JobRoleTree.create();
    
    jobRoleTree.setItems(this.ClientJobRoles);

    return jobRoleTree;
  }

  getJobRoleGroups(): JobRoleGroup[] {
    return [...this.RoleGroups.sort((a,b) => a.OrderNo - b.OrderNo)];
  }

  getAllTagCategories(): TagTree {
    let tagTree = TagTree.create();

    tagTree.setItems(this.TagCategories);

    return tagTree;
  }

  getRoleGroupsTagCategoryLookup(): { [k: number]: TagTree } {
    let tagCategoryLookup = {};

    this.RoleGroups.forEach(roleGroup => {
      let tagTree = TagTree.create();
      let tags = this.TagCategories.filter(tc => tc.RoleGroups.includes(roleGroup.Id));
      tagTree.setItems(tags);
      tagCategoryLookup[roleGroup.Id] = tagTree;
    });

    return tagCategoryLookup;
  }

  getJobRolesTagCategoryLookup(): { [k: string]: TagTree } {
    let tagCategoryLookup = {};

    this.ClientJobRoles.forEach(jobRole => {
      let jobRoleIds = [jobRole.Id].concat(jobRole.JobRoles.map(jr => jr.Id));

      jobRoleIds.forEach(jrId => {
        let tagTree = TagTree.create();
        let tags = this.TagCategories.filter(tc => tc.JobRoles.some(jr => jr === jrId));
        tagTree.setItems(tags);
        tagCategoryLookup[jrId] = tagTree;
      });
    });

    return tagCategoryLookup;
  }
}

@Injectable({
  providedIn: 'root'
})
export class JobSetupService {
  private readonly _roleConfig: BehaviorSubject<JobRoleConfig> = new BehaviorSubject(null);

  readonly roleConfig$ = this._roleConfig.asObservable().pipe(filter(x => !!x));

  constructor(private http: HttpService) {}

  refreshJobRoleSetupData(clientId: string) : Observable<JobRoleConfig> {
    this._roleConfig.next(null);

    return this.getJobRoleSetupData(clientId);
  }

  private getJobRoleSetupData(clientId: string) : Observable<JobRoleConfig> {
    let url: string = `/clients/jobrolesetup?clientId=${clientId}`;

    return this.http.get(url).pipe(
      map(jobRoleSetupResult => {
        let jobRoleConfig = new JobRoleConfig();

        let jobRoles = jobRoleSetupResult.JobRoles;

        jobRoleConfig.AllJobRoles = JobRoleTree.create();
        jobRoleConfig.AllJobRoles.setItems(jobRoles);

        jobRoleConfig.ClientJobRoles = JobRoleTree.create();

        let clientJobRoles = jobRoleConfig.AllJobRoles
          .filter(jr => 
            jobRoleSetupResult.ClientJobRolePermissions.length === 0 || 
            (jobRoleSetupResult.ClientJobRolePermissions.includes(jr.Id) || jobRoleSetupResult.ClientJobRolePermissions.includes(jr.RoleId)));

        jobRoleConfig.ClientJobRoles.setItems(clientJobRoles);

        jobRoleConfig.RoleGroups = jobRoleSetupResult.RoleGroups;
        jobRoleConfig.TagCategories = jobRoleSetupResult.TagCategories;

        this._roleConfig.next(jobRoleConfig);

        return jobRoleConfig;
      })
    );
  }
}
