import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';

export const TokenKey = 'token';
export const RefreshKey = 'refresh';
export const ExpiresKey = 'expires';
export const KeepLoggedKey = 'keepLoggedIn';

export const RoleTypeTabKey = 'role_type_tab';
export const BookSupplyTypeKey = 'book_supply_type_tab';
export const JobTypeTabKey = 'job_type_tab';

@Injectable()
export class AuthService {
  private BASE_URL: string = environment.apiBaseURL;
  private headers: HttpHeaders = new HttpHeaders({
    'Accept': 'application/json',
    'Content-Type': 'application/x-www-form-urlencoded'
  });

  public LogInEvent = new EventEmitter<any>();
  public LogOutEvent = new EventEmitter<any>();

  public ThirdPartyServicesAuthenticated: boolean = false;

  constructor(
    private http: HttpClient,
  ) {
  }

  login(username, password): Promise<any> {
    const baseUrl = `${this.BASE_URL}`.endsWith('/api') ? `${this.BASE_URL}`.slice(0, -4) : `${this.BASE_URL}`;
    const url = `${baseUrl}/token`;
    const params = new URLSearchParams('');

    params.set('username', username);
    params.set('password', password);
    params.set('client_id', environment.loginBaseConf.client_id);
    params.set('client_secret', environment.loginBaseConf.client_secret);
    params.set('grant_Type', environment.loginBaseConf.Grant_Type);

    return this.http.post(url, params.toString(), { headers: this.headers }).toPromise().then(response => {
      this.pipeLogin(response['access_token'], response['refresh_token'], response['.expires']);
      this.LogInEvent.emit();
    });
  }

  pipeLogin(token: string, refToken: string, expires: string) {
    this.token = token;
    this.refToken = refToken;
    this.expires = expires;
  }

  set keepLoggedIn(val: boolean) {
    if (val) {
      sessionStorage.removeItem(KeepLoggedKey);
      localStorage.setItem(KeepLoggedKey, val.toString());
    } else {
      localStorage.removeItem(KeepLoggedKey);
      sessionStorage.setItem(KeepLoggedKey, val.toString());
    }
  }

  get keepLoggedIn(): boolean {
    const logged = localStorage.getItem(KeepLoggedKey) ?
      localStorage.getItem(KeepLoggedKey) : sessionStorage.getItem(KeepLoggedKey);

    if (logged === 'true') {
      return true;
    } else {
      return false;
    }
  }

  set expires(expires: string) {
    if (this.keepLoggedIn) {
      localStorage.setItem(ExpiresKey, expires);
    } else {
      sessionStorage.setItem(ExpiresKey, expires);
    }
  }

  get expires() {
    if (this.keepLoggedIn) {
      return localStorage.getItem(ExpiresKey);
    } else {
      return sessionStorage.getItem(ExpiresKey);
    }
  }

  set token(token) {
    if (this.keepLoggedIn) {
      localStorage.setItem(TokenKey, token);
    } else {
      sessionStorage.setItem(TokenKey, token);
    }
  }

  get token() {
    if (this.keepLoggedIn) {
      return localStorage.getItem(TokenKey);
    } else {
      return sessionStorage.getItem(TokenKey);
    }
  }

  get refToken() {
    if (this.keepLoggedIn) {
      return localStorage.getItem(RefreshKey);
    } else {
      return sessionStorage.getItem(RefreshKey);
    }
  }

  set refToken(refresh) {
    if (this.keepLoggedIn) {
      localStorage.setItem(RefreshKey, refresh);
    } else {
      sessionStorage.setItem(RefreshKey, refresh);
    }
  }

  refreshToken(refreshToken): Observable<any> {
    const baseUrl = `${this.BASE_URL}`.endsWith('/api') ? `${this.BASE_URL}`.slice(0, -4) : `${this.BASE_URL}`;
    const url = `${baseUrl}/token`;
    const params = new URLSearchParams();

    params.set('refresh_token', refreshToken);
    params.set('client_id', environment.loginBaseConf.client_id);
    params.set('client_secret', environment.loginBaseConf.client_secret);
    params.set('grant_type', 'refresh_token');

    return this.http.post(url, params.toString(), { headers: this.headers });
  }

  logout(): void {
    sessionStorage.removeItem(TokenKey);
    sessionStorage.removeItem(RefreshKey);
    sessionStorage.removeItem(ExpiresKey);
    localStorage.removeItem(TokenKey);
    localStorage.removeItem(ExpiresKey);
    localStorage.removeItem(RefreshKey);

    localStorage.removeItem(RoleTypeTabKey);
    localStorage.removeItem(BookSupplyTypeKey);
    localStorage.removeItem(JobTypeTabKey);
    
    this.tryLogoutMoEngage();
    this.ThirdPartyServicesAuthenticated = false;

    this.LogOutEvent.emit();
  }

  isLoggedIn(): boolean {
    if (this.expires === null) {
      return false;
    }

    const tokenExpiration: Date = new Date(this.expires);
    const now: Date = new Date();

    if (this.token !== null && now < tokenExpiration) {
      return true;
    }
    return false;
  }

  resetPassword(email): Observable<any> {
    const url = `${this.BASE_URL}/account/resetpasswordcustom`;
    let params = new HttpParams();

    params = params.append('email', email);

    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), params: params };

    return this.http.get(url, options).pipe(result => result);
  }

  changePassword(email, password, token): Observable<any> {
    const url = `${this.BASE_URL}/account/setpasswordforcode`;
    const jsonHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });
    const params = {
      'Email': email,
      'Password': password,
      'Token': token
    };

    return this.http.post(url, params, { headers: jsonHeaders }).pipe(result => result);
  }

  tokenValid(email, token): Observable<any> {
    const url = `${this.BASE_URL}/account/passwordresettokenisvalid`;
    let params = new HttpParams();

    params = params.append('email', email);
    params = params.append('token', token);

    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), params: params };

    return this.http.get(url, options).pipe(result => result);
  }

  tryLogoutMoEngage() {
    try {
      (window as any).Moengage.destroy_session();
    }
    catch(e) {}
  }
}
