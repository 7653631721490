interface Date {
  addDays(days);

  removeDays(days);

  addHours(h);
}

Date.prototype.addDays = function (days: number) {
  const date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
};
Date.prototype.removeDays = function (days: number) {
  const date = new Date(this.valueOf());
  date.setDate(date.getDate() - days);
  return date;
};
Date.prototype.addHours = function (h) {
  this.setHours(this.getHours() + h);
  return this;
};
