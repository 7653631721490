import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class VersionCheckService {
  constructor(private http: HttpClient) { }

  configUrl = 'assets/version.json';

  getUpdate(){
    return this.http.get(this.configUrl);
  }
}