import { Component, OnInit } from '@angular/core';
import { AuthService, OpogoService, RegistrationService } from "@app/services";

@Component({
  selector: 'app-embedded-signin-panel',
  templateUrl: './embedded-signin-panel.component.html',
  styleUrls: ['./embedded-signin-panel.component.scss']
})

export class EmbeddedSigninPanelComponent implements OnInit {
  userDetails: any;
  loggedIn: boolean;

  constructor(
    public opogoService: OpogoService,
    private auth: AuthService,
    private registration: RegistrationService ) { }

  ngOnInit() {
    this.loggedIn = this.isLoggedIn();

    if (this.loggedIn) {
      this.registration.getLoggedInUserDetails().subscribe(
        result => {
          // console.log('User details', result);
          this.userDetails = result;
        },
        error => {
          console.log(error);          
        }
      )
    }

  }
  
  isLoggedIn():boolean {
    return this.auth.isLoggedIn();
  }

  signOut() {
    this.auth.logout();
    this.loggedIn = false;
  }
}