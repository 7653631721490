import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import {Observable, Subject} from 'rxjs';
import { AvailabilityDay } from '@app/models/availability-day.model';
import { map } from 'rxjs/operators';
import { Candidate, TalentPoolCandidateDetailed } from '@app/models/candidate.model';
import { TalentPoolResult } from '@app/models/talent-pool.model';
import { CandidateSelection } from '@modules/review-booking/data';
import { Dayjs } from 'dayjs';
import {TalentPoolCandidateJob} from '@interfaces';

export interface CandidateDetailsRequest {
	CandidateId: string;
	IncludeGeneralDetails?: boolean;
	IncludeTestimonials?: boolean;
	IncludeAvailability?: boolean;
	AvailabilityFrom?: Dayjs | string | null;
	AvailabilityTo?: Dayjs | string | null;
	IncludeJobs?: boolean;
	JobsCompletedAtMySchool: boolean;
	IncludeCourses?: boolean;
}

@Injectable()
export class TalentPoolService {
  selectedForJobList: CandidateSelection[];
  candidateId: string;
  candidateType: string;

  private candidateSelectionUpdateSubject = new Subject<CandidateSelection>();
  candidateSelectionUpdate$: Observable<CandidateSelection> = this.candidateSelectionUpdateSubject.asObservable();

  constructor(private http: HttpService) {}

  /**
   * Gets filtered candidates from client's talent pool. Current filters are:
   *  - Talent Pool
   *  - Recommended
   *  - Approved Candidates (For explore section)
   * 
   * @param request 
   * @param clientId 
   */
  getFilteredTalentPoolCandidates(request: any, clientId: string): Observable<TalentPoolResult> {
    let url: string = `/ClientTalentPool/FilteredCandidates?clientId=${clientId}`;

    return this.http.post(url, request).pipe(
      map(talentGroup => {
        let candidates = [];

        talentGroup.Candidates.forEach(candidate => {
          candidates.push(new Candidate().deserialize(candidate));
        });

        talentGroup.Candidates = candidates;

        return talentGroup;
      })
    );
  }

  /**
   * Gets candidate details for profile or modal. Currently a very bulky one because of all the different use cases
   * and amount of data we have to return.
   * 
   * @param request 
   * @param clientId 
   */
  getCandidateDetails(request: CandidateDetailsRequest, clientId: string): Observable<TalentPoolCandidateDetailed> {
    let url: string = `/ClientTalentPool/CandidateDetails?clientId=${clientId}`;

    return this.http.post(url, request).pipe(
      map(candidate => {
        return new TalentPoolCandidateDetailed().deserialize(candidate);
      })
    );
  }

  /**
   * Gets candidate testimonials
   * 
   * @param candidateId 
   */
  getTestimonials(candidateId: string) {
    let url: string = '/ClientTalentPool/CandidateDetailsGetTestimonials';

    let request = this.createRequest(candidateId, false, true)

    return this.http.post(url, request);
  }

  /**
   * Gets candidate availability for client
   * 
   * @param candidateId 
   * @param clientId 
   * @param availabilityFrom 
   * @param availabilityTo 
   */
  getAvailability(candidateId: string, clientId: string, availabilityFrom: any, availabilityTo: any) {
    let url: string = `/ClientTalentPool/CandidateDetailsGetAvailability?candidateId=${candidateId}&clientId=${clientId}&availabilityFrom=${availabilityFrom}&availabilityTo=${availabilityTo}`;

    return this.http.get(url).pipe(
      map(availabilityDays => {
        let availabilities = [];

        availabilityDays.forEach(availabilityDay => {
          availabilities.push(new AvailabilityDay().deserialize(availabilityDay));
        });

        return availabilities;
      }));
  }

  /**
   * Get candidate's jobs for client
   * 
   * @param candidateId 
   * @param clientId 
   * @param jobsCompletedAtMySchool 
   */
  getCandidateJobs(request: CandidateDetailsRequest, clientId: string): Observable<TalentPoolCandidateJob[]> {
    const url: string = `/ClientTalentPool/CandidateDetailsGetJobs?clientId=${clientId}`;

    return this.http.post(url, request);
  }

  /**
   * Notify consultant that the school is interested in booking a member
   * 
   * @param candidateId 
   * @param jobType 
   */
  notifyCandidateInterest(candidateId: string, clientId: string, jobType: number) {
    let url: string = `/ClientTalentPool/Interest/${candidateId}?jobType=${jobType}clientId=${clientId}`;

    return this.http.get(url);
  }

  /**
   * Add member to Talent pool
   * 
   * @param {String} candidateId
   * @param {String} clientId
   */
  addCandidateToTalentPool(candidateId: string, clientId: string): Observable<any> {
    let url: string = `/ClientTalentPool/${clientId}/Add?candidateId=${candidateId}`;

    return this.http.get(url);
  }

  /**
   * Remove member from Talent pool
   *
   * @param {String} candidateId
   * @param {String} clientId
   */
  removeCandidateFromTalentPool(candidateId: string, clientId: string): Observable<any> {
    let url: string = `/ClientTalentPool/${clientId}/Remove?candidateId=${candidateId}`;

    return this.http.get(url);
  }

  updateCandidateSelection(progress: CandidateSelection) {
    this.candidateSelectionUpdateSubject.next(progress);
  }

  createRequest(candidateId: string,
    includeGeneralDetails: boolean = true,
    includeTestimonials: boolean = false,
    includeAvailability: boolean = false,
    availabilityFrom: Dayjs | string = null,
    availabilityTo: Dayjs | string = null,
    includeJobs: boolean = false,
    jobsCompletedAtMySchool: boolean = false,
    includeCourses: boolean = false): CandidateDetailsRequest {
      return <CandidateDetailsRequest> {
        CandidateId: candidateId,
        IncludeGeneralDetails: includeGeneralDetails,
        IncludeTestimonials: includeTestimonials,
        IncludeAvailability: includeAvailability,
        AvailabilityFrom: availabilityFrom,
        AvailabilityTo: availabilityTo,
        IncludeJobs: includeJobs,
        JobsCompletedAtMySchool: jobsCompletedAtMySchool,
        IncludeCourses: includeCourses
      };
    }
}
