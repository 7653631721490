import { Component, OnInit } from '@angular/core';
import { AuthService } from "../../../services/auth.service";
import { RegistrationService } from '../../../services/registration.service';

@Component({
  selector: 'app-signed-in',
  templateUrl: './signed-in.component.html',
  styleUrls: ['./signed-in.component.scss']
})
export class SignedInComponent implements OnInit {
  userDetails: any;

  constructor(
    private auth: AuthService,
    private registration: RegistrationService
  ) { }

  ngOnInit() {

    if (this.isLoggedIn()) {
      this.registration.getLoggedInUserDetails().subscribe(
        result => {
          // console.log('User details', result);
          this.userDetails = result;
        },
        error => {
          console.log(error);          
        }
      )
    }
  }
  
  isLoggedIn():boolean {
    return this.auth.isLoggedIn();
  }
}