import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable()
export class CanActivateGuard  {
  constructor(
    private router: Router
  ) {
  }

  canActivate(
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    let component = (currentRoute.component as any);

    if (!component.canDisplayComponent) {
      this.router.navigate(['']);
      return false;
    }

    return component.canDisplayComponent;
  }
}
