<div class="container">
    <div class="row" [ngSwitch]="error">
        <div class="col-12" *ngSwitchCase="ErrorType.CreateJobFailed">
            <h2>Something went wrong creating your job</h2>
            <br>
            <p>
                Please contact Opogo at {{opogoService.servicesEmail}}
                <span *ngIf="consultantInfo?.PhoneNumber">
                    or call us on {{ consultantInfo?.PhoneNumber }}
                </span>
            <p>
        </div>
        <div class="col-12" *ngSwitchDefault>
            <h2>Page not found</h2>
            <br>
            <p><a [routerLink]="['/']">Click here</a> to navigate back to the main page</p>
        </div>
    </div>
</div>