<div class='{{"expandable-list" + (topHeader ? " top-header" : "")}}'>
  <div class="expandable-list-header row no-gutters p-3" (click)="toogleContentVisibility()">
    <div class='col'>
      <span class='font-weight-bold' [attr.data-au]="'expandable-list-title'">{{title}}</span>
      <span class="loader blue center pl-5" *ngIf="showLoader"></span>
      <span *ngIf="!showLoader" [attr.data-au]="'expandable-list-subtitle'"> {{subtitle}}</span>
    </div>
    <div class="col-1 text-right sort-icon">
      <span class="material-icons align-middle" [attr.data-au]="'expandable-list-expand-collapse'">unfold_more</span>
    </div>
  </div>
  <div class="{{(!expanded && collapsible ? 'd-none' : '')}}">
    <ng-content></ng-content>
  </div>
</div>
