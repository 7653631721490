import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'limitto'
})
export class LimitToPipe implements PipeTransform {
  transform(arr: any[], limit: number): any {
    if (!Array.isArray(arr) || !limit) {
      return arr;
    }
    return arr.slice(0, limit);
  }
}
