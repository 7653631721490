import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from './http.service';
import { HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { AuthService } from '@services/auth.service';

@Injectable()
export class CandidateService {

  constructor(
    private http: HttpService,
    private auth: AuthService
  ) {
  }

  getComplianceList(candidateId: string): Observable<any> {
    let url: string = `/candidate/${candidateId}/compliance`;
    return this.http.get(url);
  }

  getCandidateCv(candidateId: string): Observable<any> {
    let url: string = `/candidate/${candidateId}/cv`;
    return this.http.get(url);
  }

  getComplianceDocument(candidateId: string, docId: string): Observable<any> {
    let url: string = `/candidate/${candidateId}/compliance/${docId}`;
    return this.http.get(url, {responseType: 'blob'}).pipe(map(res => {
      return [{
        type: res.type,
        data: res.slice(0, res.size)
      }];
    }));
  }

  getMembersDetails(members: any): Observable<any> {
    let url: string = `/candidate/getMembersDetails`;
    return this.http.post(url, members);
  }

  getMembersDetailsByName(members: any): Observable<any> {
    let url: string = `/candidate/getMembersDetailsByName`;
    return this.http.post(url, members);
  }

  getExperts(): Observable<any> {
    /*let url: string = './assets/data/experts.json';
    return this.httpClient.get(url);*/
    return this.getMembersDetails(environment.contentExperts);
  }

  changeProfileImage(img): Observable<any> {
    let url: string = `/candidate/profile/image/update`;

    const imageFile = this.b64toFile(img);
    const headers = new HttpHeaders({
      'Authorization': `bearer ${this.auth.token}`,
    });

    const formdata: FormData = new FormData();
    formdata.append('file', imageFile);

    return this.http.post(url, formdata, {headers: headers});
  }

  b64toFile(dataURI): Blob {
    // convert the data URL to a byte string
    const byteString = atob(dataURI.split(',')[1]);

    // pull out the mime type from the data URL
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // Convert to byte array
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    // Create a blob that looks like a file.
    const blob = new Blob([ab], {'type': mimeString});
    blob['lastModifiedDate'] = (new Date()).toISOString();
    blob['name'] = 'file';

    // Figure out what extension the file should have
    switch (blob.type) {
      case 'image/jpeg':
        blob['name'] += '.jpg';
        break;
      case 'image/png':
        blob['name'] += '.png';
        break;
    }

    // return new File([blob], blob['name'], { type: mimeString });
    return blob;
  }
}
