import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'secondsToTime'
})
export class SecondsToTimePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (value) {
      if (args) {
        let minutes = Math.floor(value / 60);
        let seconds = value - (minutes * 60);
        return minutes > 0 ? minutes > 60 ? `${Math.floor(minutes / 60)}h ${minutes % 60}m ${seconds}s` : `${minutes}m ${seconds}s` : `${seconds}s`;
      } else {
        let minutes = Math.floor(value / 60);
        return minutes > 0 ? minutes > 60 ? `${Math.floor(minutes / 60)}h ${minutes % 60}min` : `${minutes}min` : '';
      }
    }
    return '';
  }

}
