import { BaseModel } from "./base.model";

type parentGetter = () => TagCategory;

export class TagTree extends Array<TagCategory> {
  readonly length: number;

  readonly [n: number]: TagCategory;

  private constructor(items?: Array<TagCategory>) {
    super(...items);
  }

  static create<T>(): TagTree {
    return Object.create(TagTree.prototype);
  }

  setItems(data: any) {
    data.forEach(category => {
      this.push(new TagCategory().deserialize(category));
    });
  }

  setSelected(id: string) {
    const tag = this.findTag(id);
    if (tag) {
      tag.selected = true;
      tag.parent.selectedTags.push(tag);
    }
  }

  getSelected() {
    let res: GenericTag[] = [];
    this.forEach(category => {
      res = res.concat(category.selectedTags);
    });

    return res.sort((a,b) => a.compareSelectionTimeTo(b));
  }

  getSelectedIds() {
    const res: GenericTag[] = [];
    this.forEach(category => {
      category.selectedTags.forEach(tag => {
        res.push(tag);
      });
    });
    return res.sort((a,b) => a.compareSelectionTimeTo(b)).map(tag => tag.Id);
  }

  findCategory(id: string): TagCategory {
    for (let i = 0; i < this.length; i++) {
      if (id === this[i].Id) {
        return this[i];
      }
    }
  }

  findTag(id: string): GenericTag {
    for (let i = 0; i < this.length; i++) {
      for (let j = 0; j < this[i].Tags.length; j++) {
        if (this[i].Tags[j].Id === id) {
          return this[i].Tags[j];
        }
      }
    }
  }

  clearAllSelections() {
    this.forEach(category => {
      category.clearSelected();
    });
  }
}

export enum ToggleStatus {
  removed = 0,
  added = 1,
  notFoundOnAdd = 2,
  notFoundOnRemove = 3,
  foundOnAdd = 4
}

export class TagCategory {
  CategoryType: number = null;
  Description: string = null;
  ForContent: boolean = null;
  ForJobs: boolean = null;
  ForMember: boolean = null;
  ForPartner: boolean = null;
  Id: string = null;
  IsMultiple: boolean = null;
  Name: string = null;
  Tags: GenericTag[] = [];
  selectedTags: GenericTag[] = [];
  opened = false;

  deserialize(input: any) {
    if (typeof input === 'undefined' || input === null) {
      return this;
    }

    Object.keys(this)
      .forEach(key => {
        if (typeof input[key] !== 'undefined') {
          if (key === 'Tags' || key === 'selectedTags') {
            input[key].forEach(tag => {
              this[key].push(new GenericTag().deserialize(tag, () => {
                  return this;
                })
              );
            });
          } else {
            this[key] = input[key];
          }
        }
      });
    return this;
  }

  selectTag(data: string | GenericTag) {
    const id = typeof data === 'string' ? data : data.Id;
    const index = this.selectedTags.findIndex(item => item.Id === id);
    if (index === -1) {
      const tag = this.find(id);
      if (tag) {
        tag.selected = true;
        tag.selectedAt = new Date();
        this.selectedTags.push(tag);
        return ToggleStatus.added;
      }
      return ToggleStatus.notFoundOnAdd;
    }
    return ToggleStatus.foundOnAdd;
  }

  unselectTag(data: string | GenericTag) {
    const id = typeof data === 'string' ? data : data.Id;
    const index = this.selectedTags.findIndex(item => item.Id === id);
    if (index > -1) {
      this.selectedTags[index].selected = false;
      this.selectedTags[index].selectedAt = null;
      this.selectedTags.splice(index, 1);
      return ToggleStatus.removed;
    }
    return ToggleStatus.notFoundOnRemove;
  }

  toggleTagSelection(data: string | GenericTag) {
    const id = typeof data === 'string' ? data : data.Id;
    const index = this.selectedTags.findIndex(item => item.Id === id);
    if (index === -1) {
      const tag = this.find(id);
      if (tag) {
        tag.selected = true;
        tag.selectedAt = new Date();
        this.selectedTags.push(tag);
        return ToggleStatus.added;
      } else {
        return ToggleStatus.notFoundOnAdd;
      }
    } else {
      this.selectedTags[index].selected = false;
      this.selectedTags[index].selectedAt = null;
      this.selectedTags.splice(index, 1);
      return ToggleStatus.removed;
    }
  }

  find(data: string | GenericTag) {
    const id = typeof data === 'string' ? data : data.Id;
    return this.Tags.find(item => id === item.Id);
  }

  findIndex(data: string | GenericTag) {
    const id = typeof data === 'string' ? data : data.Id;
    return this.Tags.findIndex(item => id === item.Id);
  }

  findSelected(data: string | GenericTag) {
    const id = typeof data === 'string' ? data : data.Id;
    return this.selectedTags.find(item => id === item.Id);
  }

  findSelectedIndex(data: string | GenericTag) {
    const id = typeof data === 'string' ? data : data.Id;
    return this.selectedTags.findIndex(item => id === item.Id);
  }

  clearSelected() {
    this.selectedTags.forEach(tag => {
      tag.selected = false;
      tag.selectedAt = null;
    });
    this.selectedTags = [];
  }
}

export class MemberTagCategory {
  Id: string = null;
  Name: string = null;
  Description: string = null;
  OrderNo?: number = null;
  Tags: Tag[] = [];
}

export class Tag extends BaseModel {
  Id: string = null;
  TagCategoryId: string = null;
  TagCategoryName: string = null;
  Name: string = null;
  CategoryType: number = null;
  RelevantJobRoles: string[] = [];
}

export class GenericTag extends Tag {
  ForMember: boolean = null;
  selected = false;
  selectedAt: Date = null;

  deserialize(input: any, parent?: parentGetter) {
    if (typeof input === 'undefined' || input === null) {
      return this;
    }

    Object.keys(this)
      .forEach(key => {
        if (typeof input[key] !== 'undefined') {
          this[key] = input[key];
        }
      });
    if (parent) {
      this._parent = parent;
    }
    return this;
  }


  get hasParent() {
    return !!this.parent;
  }

  get parent(): TagCategory {
    return this._parent();
  }

  private _parent() {
    return null;
  }

  toggleSelection() {
    this.parent.toggleTagSelection(this);
  }

  compareSelectionTimeTo(tag: GenericTag): number {
    if (!this.selectedAt && !tag.selectedAt) {
      return 0;
    }
    
    if (!this.selectedAt) {
      return -1;
    }
    
    if (!tag.selectedAt) {
      return 1;
    }

    return tag.selectedAt.getTime() - this.selectedAt.getTime();
  }
}
