import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';

import { AuthService, DeviceService } from './../../../services';
import { PasswordStrengthValidator } from './../../../helpers';
import { default as Utils } from './../../../utils';
import firebase from 'firebase/compat/app';
import { getAnalytics, logEvent } from 'firebase/analytics';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  analytics = getAnalytics();

  serverError: string;
  email: string;
  token: string;
  submitted = false;
  loading = false;
  confirmPasswordFocus = false;
  changePasswordForm: FormGroup<any>;


  constructor(
    private auth: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    public device: DeviceService
  ) {
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      params = Utils.paramsKeyToLower(params);
      this.email = params['email'];
      this.token = params['token'];
      this.isTokenValid();
    });
  }

  ngOnInit() {
    this.changePasswordForm = this.fb.group({
      newPassword: ['', Validators.compose([Validators.required, Validators.minLength(8), PasswordStrengthValidator])],
      confirmPassword: ['', Validators.compose([Validators.required])],
    });
  }

  onSubmit(): void {
    this.submitted = true;
    if (this.changePasswordForm.valid) {
      this.loading = true;
      this.auth.changePassword(this.email, this.changePasswordForm.get('newPassword').value, this.token).subscribe(
        () => {
          this.router.navigate(['/resetpasswordsuccessful']);
        },
        error => {
          this.loading = false;
          this.serverError = error.error.error_description;
          this.changePasswordForm.get('newPassword').setErrors({ serverError: true });
          this.changePasswordForm.get('confirmPassword').setErrors({ serverError: true });
        }
      );
    }
  }

  logEventForSetPassword() {
    logEvent(this.analytics, 'click_get-new-password');
  }

  isTokenValid() {
    this.auth.tokenValid(this.email, this.token).subscribe(
      result => {
        if (result._body === 'false') {
          this.router.navigate(['/forgot-password'], { queryParams: { tokenHasExpired: true } });
        }
      },
      error => {
        this.serverError = error.error.error_description;
      }
    );
  }

}
