import { Pipe, PipeTransform } from '@angular/core';
import { LocalUrl, LocalizedUrlProvider } from '../providers/localized-url.provider';

@Pipe({
  name: 'localizeUrl'
})
export class LocalizeUrlPipe implements PipeTransform {

  constructor(private provider: LocalizedUrlProvider) { }

  transform(value: LocalUrl, ...args: unknown[]): unknown {
    return this.provider.getLocalizedUrl(value);
  }

}
