import { Injectable } from '@angular/core';

@Injectable()
export class MenuService {
  public menuRightVisible: boolean = false;

  hideRight() {
    this.menuRightVisible = !this.menuRightVisible;
  }

  constructor() {
  }

}
