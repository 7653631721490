import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { FEATURE_FLAG } from '@app/interfaces';
import { CandidateDetailsRequest, ClientDataStoreService, TalentPoolService } from '@app/services';
import { MemberTagCategory, TalentPoolCandidateDetailed } from '@app/models';
import { environment } from 'environments/environment';
import { Label } from '@app/localization/providers/localized-text.provider';
import { Subscription } from 'rxjs';

export enum CandidateTab {
  About = 0,
  Courses = 1,
  Reviews = 2
}

@Component({
  selector: 'app-candidate-details-modal',
  templateUrl: './candidate-details-modal.component.html',
  styleUrls: ['./candidate-details-modal.component.scss']
})
export class CandidateDetailsModalComponent implements OnInit, OnDestroy {
  @Input() candidateId: string;
  @Input() recommended: boolean = false;
  @Output() onClose: EventEmitter<any> = new EventEmitter<any>();

  userInfo: any;
  candidate: TalentPoolCandidateDetailed;
  showLoader: boolean = false;
  selectedTab: CandidateTab = CandidateTab.About;
  CandidateTab = CandidateTab;
  primaryProfile: any;
  showAdditionalProfiles: boolean = true;
  qualificationsCategoryId = environment.tagCategories.qualificationsCategoryId;

  // Can't find a better way to do this with css grid
  // without a ton of shady css
  leftColumnTagCategories: MemberTagCategory[] = [];
  rightColumnTagCategories: MemberTagCategory[] = [];

  // Localization stuff
  private readonly LocalizedLabel = Label;
  private readonly FEATURE_FLAG = FEATURE_FLAG;

  get TagCategories() {
    return this.candidate.TagCategories.sort((a,b) => a.OrderNo - b.OrderNo);
  }

  get ClientId() {
    return this.userInfo?.ClientId;
  }

  subscriptions: Subscription[] = [];

  constructor(
    private router: Router,
    private sanitizer: DomSanitizer,
    private clientStore: ClientDataStoreService,
    private talentService: TalentPoolService) { }

  ngOnInit() {
    this.showLoader = true;
    this.subscriptions.push(this.clientStore.clientSummary$.subscribe(clientSummary => {
      this.userInfo = clientSummary;

      this.getCandidateDetails(this.candidateId).subscribe(
        result => {
          this.candidate = result;
          this.getPrimaryProfile();
  
          // really hate to be doing this but I don't see any better way of doing it
          // since we have to mix non-tags with actual tags
          if (!this.candidate.TagCategories?.some(tc => tc.Id === this.qualificationsCategoryId) && this.candidate.Courses.length > 0) {
            let qualificationsCategory = new MemberTagCategory();
            qualificationsCategory.Id = this.qualificationsCategoryId;
            qualificationsCategory.Description = 'Has qualifications';
            qualificationsCategory.OrderNo = 5;
            this.candidate.TagCategories = [...this.candidate.TagCategories||[], ...[qualificationsCategory]];
          }
  
          this.divideTagCategoriesIntoLeftAndRightColumns();
  
          this.showLoader = false;
        },
        error => {
          this.showLoader = false;
          console.log('getCandidateDetails error:', error);
        }
      );  
    }));
  }

  getCandidateDetails(candidateId): any {
    let request = <CandidateDetailsRequest> {
      CandidateId: candidateId,
      IncludeGeneralDetails: true,
      IncludeTestimonials: true,
      IncludeAvailability: false,
      IncludeJobs: true,
      JobsCompletedAtMySchool: true,
      IncludeCourses: true 
    };

    return this.talentService.getCandidateDetails(request, this.ClientId);
  }

  close() {
    this.onClose.emit();
  }
  
  setTab(tab: CandidateTab) {
    this.selectedTab = tab;
  }

  getPrimaryProfile() {
    for (const profile of this.candidate.Profiles) {
      if (profile.IsPrimary) {
        this.primaryProfile = profile;
      }
    }

    if (!this.primaryProfile) {
      this.primaryProfile = this.candidate.Profiles[0];
      this.primaryProfile.IsPrimary = true;
    }
  }

  toggleAdditionalProfilesVisibility() {
    this.showAdditionalProfiles = !this.showAdditionalProfiles;
  }

  goToTalentProfile() {
    const url = this.router.serializeUrl(this.router.createUrlTree(['./talent/profile'], {
      queryParams: { id: this.candidateId, type: 'poolTalent' }
    }));
    window.open(url, '_blank');
  }

  getScoreStyle(score: number) {
    let style = "--rating: ";
    style += score ? score : '0';
    style += ";";
    return this.sanitizer.bypassSecurityTrustStyle(style);
  }

  divideTagCategoriesIntoLeftAndRightColumns() {
    let _this = this;
    this.candidate.TagCategories?.forEach(function(tc, idx) {
      if (idx % 2 === 0) _this.leftColumnTagCategories.push(tc);
      else _this.rightColumnTagCategories.push(tc);
    })
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
