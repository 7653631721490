import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, Router, RouterStateSnapshot } from '@angular/router';
import { ClientDataStoreService, JobSetupService } from '../services';
import { catchError, filter, map, Observable, of } from 'rxjs';

@Injectable()
export class RoleConfigResolver {

  constructor(private router: Router, private jobSetup: JobSetupService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return this.jobSetup.roleConfig$.pipe(
      map(result => result),
      catchError(error => {
        console.log(`Whoops, we're unable to resolve client role configurations. Might want to look into that! Here's the error message: ${error}`);
        
        return of(null);
      }));
  }
}