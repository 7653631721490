import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ChatCandidateContactType, ChatRoom, ClientContactSummary, ClientSummary, Consultant } from '@app/interfaces';
import { Label } from '@app/localization/providers/localized-text.provider';
import { ChatService, HeaderService } from '@app/services';
import { NotificationsService } from '@app/services/notifications.service';
import { Subscriber, Subscription } from 'rxjs';
import { delay } from 'rxjs/operators';

@Component({
  selector: 'app-chatpopup',
  templateUrl: './chatpopup.component.html',
  styleUrls: ['./chatpopup.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ChatpopupComponent implements OnInit, OnDestroy {
  localizedLabel = Label;
  
  subscriptions: Subscription[] = [];
  userInfo: ClientSummary;
  consultant: Consultant;
  defaultCandidatePhotoUrl: boolean = true;
  expanded: boolean = false;
  unreadMessages: number = 0;
  selectedTabIndex:number = 0;
  candidateChatRooms: ChatRoom[] = [];
  unreadCandidateMessages: number = 0;
  consultantChatRooms: ChatRoom[] = [];
  unreadConsultantMessages: number = 0;

  constructor(private headerService: HeaderService,
              private chatService: ChatService,
              private notificationService: NotificationsService) {
  }

  ngOnInit() {
    const headerServiceSubscription = this.headerService.headerObject
      .pipe(delay(0))
      .subscribe(header => {
        this.userInfo = header.userInfo;
        if (this.userInfo) {
          this.consultant = this.userInfo.TalentConsultant || this.userInfo.AccountManager;
        }
        this.defaultCandidatePhotoUrl = this.userInfo &&
          (this.userInfo.ProfileImageUrl === null || this.userInfo.ProfileImageUrl === "" ||
          this.userInfo.ProfileImageUrl.includes('default-member-photo.png'));
      });

    this.subscriptions.push(headerServiceSubscription);

    const newMessageSubscription = this.chatService.NewMessageEvent.subscribe((message) => {  
      this.messageReceived(message.pn_other.chatChannel);
    });

    this.subscriptions.push(newMessageSubscription);

    this.chatService.initializePubNub().subscribe(
      result => {
        this.refreshChatRooms();
      },
      error => {
        console.log(error);
      }
    )

    const jobAcceptedSubscription = this.notificationService.JobAcceptedEvent.subscribe(() => {  
      this.refreshChatRooms();
    });

    this.subscriptions.push(jobAcceptedSubscription);

    const newChatRoomCreatedSubscription = this.chatService.NewChatRoomCreated.subscribe(() => {  
      this.refreshChatRooms();
    });

    this.subscriptions.push(newChatRoomCreatedSubscription);

    const newChatRoomSelectedSubscription = this.chatService.NewChatRoomSelected.subscribe((chatRoom?: ChatRoom) => {  
      this.updateUnreadMessages(chatRoom);
    });

    this.subscriptions.push(newChatRoomSelectedSubscription);

    const showChatPopupSubscription = this.chatService.ShowChatPopup.subscribe(() => {  
      this.expanded = true;
      this.selectedTabIndex = this.chatService.selectedTabIndex;
    });

    this.subscriptions.push(showChatPopupSubscription);
  }

  updateUnreadMessages(chatRoom: ChatRoom) {
    if (chatRoom === null) {
      return;
    }

    if (chatRoom.ChatType === ChatCandidateContactType.Candidate) {
      this.unreadCandidateMessages -= chatRoom.UnreadMessages;
    }

    if (chatRoom.ChatType === ChatCandidateContactType.Consultant) {
      this.unreadConsultantMessages -= chatRoom.UnreadMessages;
    }

    chatRoom.UnreadMessages = 0;
    this.unreadMessages = this.unreadCandidateMessages + this.unreadConsultantMessages;
    this.chatService.unreadMessages = this.unreadMessages;
  }

  private refreshChatRooms() {
    this.chatService.getAllChatRooms().subscribe(
      result => {

        if (result === null) {
          return;
        }

        let chatRooms: ChatRoom[] = result;
        this.chatService.ChatRooms = result;

        this.candidateChatRooms = chatRooms.filter(r => r.ChatType === ChatCandidateContactType.Candidate);
        this.unreadCandidateMessages = 0;
        this.candidateChatRooms.forEach(r => this.unreadCandidateMessages += r.UnreadMessages);

        this.consultantChatRooms = chatRooms.filter(r => r.ChatType === ChatCandidateContactType.Consultant);
        this.unreadConsultantMessages = 0;
        this.consultantChatRooms.forEach(r => this.unreadConsultantMessages += r.UnreadMessages);

        this.unreadMessages = this.unreadCandidateMessages + this.unreadConsultantMessages;
        this.chatService.unreadMessages = this.unreadMessages;

        chatRooms.forEach( r => this.chatService.subscribeChannel(r.ChannelId));
        this.chatService.ChatRoomsRefreshed.emit();
      },
      error => {
        console.log(error);
      }
    );
  }

  messageReceived(ChannelId: any) {

    if (this.chatService.SelectedChatRooms.find(s => s.ChatRoom.ChannelId === ChannelId)) {
      return;
    }

    this.unreadMessages++;
    this.chatService.unreadMessages = this.unreadMessages;
    let isCandidateMessage: boolean = false;

    this.candidateChatRooms.forEach( r => {
      if (r.ChannelId == ChannelId) {
        r.UnreadMessages++;
        this.unreadCandidateMessages++;
        isCandidateMessage = true;
      }
    });

    this.consultantChatRooms.forEach( r => {
      if (r.ChannelId == ChannelId) {
        r.UnreadMessages++;
        this.unreadConsultantMessages++;
      }
    });

    if (isCandidateMessage) {
      this.candidateChatRooms = [...this.candidateChatRooms];
    }
    else {
      this.consultantChatRooms = [...this.consultantChatRooms];
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

  toogleChatPopup() {
    this.expanded = !this.expanded;
  }

  setTab(tabTitle: string) {
    this.selectedTabIndex = tabTitle.includes("Candidate") ? 0 : 1;
  }

  getSelectedChatRoomsSize() {
    return this.chatService.selectedChatRoomsSize;
  }

}
