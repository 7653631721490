<div *ngIf="!isLink" class="notifications-icon" (click)="open(NotificationsModal)">
  <span class="material-icons">notifications</span>
  <span *ngIf="notifications && notifications.length > 0" class="notifications-count">{{ getNotificationsCount() }}</span>     
</div>

<div *ngIf="isLink" class="notifications-link" (click)="open(NotificationsModal)">
  <span class="notifications-link-text">NOTIFICATIONS</span>
  <span *ngIf="notifications && notifications.length > 0" class="notifications-count">{{ getNotificationsCount() }}</span>     
</div>

<ng-template #NotificationsModal>
  <div class="row no-gutters p-3 dialog-header">
    <div class="col modal-title">Notifications</div>
    <div class="col-auto close-modal" (click)="modalRef.hide()">
      <span class="material-icons">expand_less</span>
    </div>
    <div  *ngIf="notifications.length !== 0" class="col-12 text-right dismiss-all" (click)="deleteAllNotifications()">
      <span class="delete-icon">&nbsp;&nbsp;&nbsp;&nbsp;</span>
      <span class="px-2">Dismiss all</span>
    </div>
  </div>

  <div *ngIf="notifications.length === 0" class="p-3">No new notifications.</div>

  <div *ngFor="let notification of notifications" class="row no-gutters p-2 notification-entry"
        (click)="navigateTo(notification)">
    
    <div class="col-2 text-center notification-icon">
      <span class="material-icons warning" 
        *ngIf="notificationsConfig.get(notification.message.Type).icon === NotificationIcon.Warning">warning</span>
      <span class="material-icons ok" 
        *ngIf="notificationsConfig.get(notification.message.Type).icon === NotificationIcon.Ok">check_circle</span>
        <span class="material-icons selected" 
        *ngIf="notificationsConfig.get(notification.message.Type).icon === NotificationIcon.Review">thumb_up</span>  
    </div>

    <div class="col notification-body">
      <div class="notification-text">{{notification.message.Text}}</div>
      <div class="{{'notification-date' + (notificationsConfig.get(notification.message.Type).icon === NotificationIcon.Warning ? ' warning' : ' ok')}}">{{notification.message.Time | dateAgo}}</div>
    </div>

    <div class="col-2 px-3 text-right dismiss-entry" (click)="deleteNotification(notification)"><span>X</span></div>

  </div>


</ng-template>