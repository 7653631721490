import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { debounceTime } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';

@Injectable()
export abstract class NavListComponent implements OnInit, OnDestroy {
  showActions = false;
  toggler: Subject<boolean> = new Subject();
  subscription: Subscription;

  constructor(
    protected router: Router
  ) {

  }

  ngOnInit() {
    this.subscription = this.toggler.pipe(
      debounceTime(200)
    ).subscribe(value => {
      this.showActions = value;
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  toggleActions() {
    this.showActions = !this.showActions;
  }

  navigate(route: any[]) {
    this.router.navigate(route);
  }
}
