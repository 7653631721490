<div class="reference-form container pt-4" *ngIf="reference && !submitted && !error">
    <div class="row justify-content-center">
        <div class="square user-profile-img candidate-profile__left op-avatar mb-3 mt-md-3">
            <img class="content" [src]="reference.Candidate.Image" *ngIf="reference.Candidate.Image">
        </div>
        <div class="col-12 text-center reference__name mb-2">
            <h2>{{ reference.ReferenceType.Description }} for {{ reference.Candidate.FullName }}</h2>
        </div>
        <div class="col-12 text-center reference__work-interval" *ngIf="!(disabled && reference.ReferenceType.Name === CharacterReference)">
            <p *ngIf="reference.Role && reference.OrganisationName && reference.ReferenceType.Name !== LOGC"> {{ reference.Role }} at {{ reference.OrganisationName }} </p>
                <span>{{ referencePeriod }}</span>
            <p *ngIf="!disabled"><strong>Please answer all questions</strong></p>
        </div>
    </div>
    
    <ng-container [ngSwitch]="reference.ReferenceType.Name">
        <app-reference-questionnaire *ngSwitchCase="Reference" 
            [reference]="reference" 
            [submissionError]="submissionError"
            [disabled]="disabled"
            [submitLoader]="submitLoader"
            [onUpdateReference]="onUpdateReference.bind(this)">
        </app-reference-questionnaire>
        <app-logc-questionnaire *ngSwitchCase="LOGC" 
            [reference]="reference" 
            [submissionError]="submissionError"
            [disabled]="disabled"
            [submitLoader]="submitLoader"
            [onUpdateReference]="onUpdateReference.bind(this)">
        </app-logc-questionnaire>
        <app-character-reference-questionnaire *ngSwitchCase="CharacterReference" 
            [reference]="reference" 
            [submissionError]="submissionError"
            [disabled]="disabled"
            [submitLoader]="submitLoader"
            [onUpdateReference]="onUpdateReference.bind(this)">
        </app-character-reference-questionnaire>
    </ng-container>
</div>  

<div class="container refrence__thank-you" *ngIf="submitted">
    <div class="row justify-content-center text-center mx-0">
        <div class="col">
            <div class="square user-profile-img candidate-profile__left op-avatar mb-3 mt-0">
                <img class="content" [src]="reference.Candidate.Image" *ngIf="reference.Candidate.Image">
            </div>
            <div class="text-center mb-2">
                <h2>Thank you for providing a reference for {{ reference.Candidate.FullName }}</h2>
                <p>If you'd like to update this reference, or have any questions please email <a href="mailto:{{opogoService.servicesEmail}}">{{opogoService.servicesEmail}}</a></p>
            </div>
        </div>
    </div>
</div>

<div class="container pt-4" *ngIf="error">
    <div class="row justify-content-center error">
        <p>The reference request cannot be found. Sorry for any inconvenience caused.</p>
    </div>
</div>
