<div class="alert-box text-center">
    <div class="modal-header box-header">
        {{ title }}
        <button type="button" class="close pull-right" (click)="hideConfirmationDialog()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="box-body">
        <div class="modal-body pt-3" innerHTML="{{ message }}">
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-danger" (click)="action(true)">{{ confirmButton }}</button>
            <button type="button" class="btn btn-success" (click)="action(false)">{{ cancelButton }}</button>
        </div>
    </div>
</div>