import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { LearnService, MobileService, RegistrationService } from '@app/services';
import { default as Utils } from '../../../utils';

@Component({
  selector: 'app-validate-email',
  templateUrl: './validate-email.component.html',
  styleUrls: ['./validate-email.component.scss']
})
export class ValidateEmailComponent implements OnInit {
  showLoader: boolean;
  serverError: string;
  userId: string;
  token: string;
  emailVerified: boolean;
  mobile: boolean = false;
  show: boolean = true;
  mediaShow: boolean = false;

  constructor(
    private registrationService: RegistrationService,
    private activatedRoute: ActivatedRoute,
    private learnService: LearnService,
    private mobileService: MobileService
  ) {
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      params = Utils.paramsKeyToLower(params);
      this.userId = params['userid'];
      this.token = params['token'];
      this.isEmailValid();
    });
  }

  ngOnInit() {
    if(this.mobileService.isMobile()) {
      console.log('mobile');
      this.mobile = true;
      this.mediaShow = true;
      this.show = false;
     }
  }

  signInCommunity(){
    this.learnService.gotoLearn();
  }

  signInApp(){
    if(this.mobile){
      this.mobileService.goToMobileStore();
    }else{
      document.getElementsByTagName("h2")[0].innerHTML = "Please download and open the Opogo app <br> on your mobile device";
      this.mediaShow = false;
      this.show = false;
    }
  }

  isEmailValid() {
    this.showLoader = true;
    this.registrationService.verifyEmail(this.userId, this.token).subscribe(
      result => {
        this.showLoader = false;
        if (result === false) {
          this.emailVerified = false;
          this.showLoader = false;
        } else {
          this.emailVerified = true;
          this.showLoader = false;
        }
      },
      error => {
        this.showLoader = false;
        console.log('error:', error);

      }
    );
  }

}
