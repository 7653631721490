import { Injectable } from '@angular/core';
import { Candidate } from '@app/models';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SubjectService {
  public _widgetActive: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _candidateBookingDetails: Subject<Candidate[]> = new Subject<Candidate[]>;

  widgetActive$: Observable<boolean> = this._widgetActive.asObservable();
  candidateDetails$: Observable<Candidate[]> = this._candidateBookingDetails.asObservable();

  updateCandidates(candidates: Candidate[]) {
    this._candidateBookingDetails.next(candidates);
  }
}
