<div
  class="nav-list-wrap"
  (mouseenter)="showActions = true"
  (mouseleave)="toggler.next(false)"
  (clickOutside)="this.showActions=false"
>
  <a
    class="face"
    [routerLinkActive]="'active'"
    [routerLinkActiveOptions]="{match:['/hub']}"
  >
    Opogo Hub
  </a>

  <ul
    (mouseenter)="toggler.next(true)"
    (mouseleave)="toggler.next(false)"
    [class.opened]="showActions"
    class="nav-list"
  >
    <li
      class="nav-li"
      [routerLinkActive]="['active']"
      [routerLinkActiveOptions]="{match:['/hub'], exact: true}"
      (click)="navigate(['/hub']); toggleActions()"
    >
      <i class="m-icon va-m">
        library_books
      </i>
      <a class="nav-link">
        Latest posts
      </a>
    </li>
    <li
      class="nav-li"
      [routerLinkActive]="['active']"
      [routerLinkActiveOptions]="{match:['/hub/experts']}"
      (click)="navigate(['/hub/experts']); toggleActions()"
    >
      <i class="m-icon va-m">
        people_alt
      </i>
      <a class="nav-link">
        Experts
      </a>
    </li>
    <li
      class="nav-li"
      [routerLinkActive]="['active']"
      [routerLinkActiveOptions]="{match:['/hub/courses']}"
      (click)="navigate(['/hub/courses']); toggleActions()"
    >
      <i class="m-icon va-m">
        play_circle_filled
      </i>
      <a class="nav-link">
        Courses
      </a>
    </li>
    <li
      class="nav-li"
      [routerLinkActive]="['active']"
      [routerLinkActiveOptions]="{match:['/hub/events']}"
      (click)="navigate(['/hub/events']); toggleActions()"
    >
      <i class="m-icon va-m">
        event
      </i>
      <a class="nav-link">
        Events
      </a>
    </li>
    <li
      *ngIf="hasContentCreationPermission"
      class="nav-li"
      [routerLinkActive]="['active']"
      [routerLinkActiveOptions]="{match:['/hub/manage-content/add']}"
      (click)="navigate(['/hub/manage-content/add']); toggleActions()"
    >
      <i class="m-icon va-m">
        add_circle
      </i>
      <a class="nav-link">
        Add content
      </a>
    </li>
    <li
      class="nav-li"
      [routerLinkActive]="['active']"
      [routerLinkActiveOptions]="{match:['/hub/manage-content'], exact: true}"
      (click)="navigate(['/hub/manage-content']); toggleActions()"
    >
      <i class="m-icon va-m">
        create
      </i>
      <a class="nav-link">
        Manage content
      </a>
    </li>
  </ul>
</div>
