<div class="container" *ngIf="!resetted">
  <div class="row justify-content-center">
    <div class="col-12 text-center">
      <h2>Forgotten password</h2>
      <form [formGroup]="forgotPasswordForm" (ngSubmit)="onSubmit()" novalidate class="forgotPasswordForm">
        <div class="form-group">
          <p class="mx-3 error-text" *ngIf="tokenHasExpired">This reset password link has expired. Please try resetting again below.</p>
          <span class="mx-3">After submitting your email address, check your email for the reset password link.</span>
          <input #email type="email" class="mt-4" formControlName="opogousr" placeholder="Enter your email address" required>
          <div *ngIf="forgotPasswordForm.get('opogousr').value === '' && forgotPasswordForm.get('opogousr').touched" class="error-msg">
              Email address is required
          </div>
          <div *ngIf="!forgotPasswordForm.get('opogousr').valid && forgotPasswordForm.get('opogousr').touched && forgotPasswordForm.get('opogousr').dirty" class="error-msg">
            Please enter a valid email address
          </div>
          <div *ngIf="notFound" class="error-msg">
            Email address does not exist
          </div>
        </div>
        <div *ngIf="serverError" class="error-msg">{{ serverError }}</div>
        <button type="submit" class="btn btn-primary mt-3">Get new password</button>
      </form>
    </div>
  </div>
</div>

<div class="container submitted-password" *ngIf="resetted">
  <div class="row justify-content-center">
    <div class="col-12 text-center">
      <p><strong>Thank you for submitting your forgotten password request.</strong></p>
      <p>Please check your email and follow the link to reset.</p>
    </div>
    <button class="btn btn-primary" [routerLink]="['/embeddedsignin']">Back to sign in</button>
  </div>
</div>
