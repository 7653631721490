<div class="nav-list-wrap" (clickOutside)="this.showActions=false">
  <span class="face" (click)="toggleActions()">
    <img *ngIf="faceImageUrl" [src]="faceImageUrl">
  </span>

  <ul
    [class.opened]="showActions"
    (mouseleave)="toggler.next(false)"
    class="nav-list"
  >
    <li
      class="nav-li"
      (click)="changeImage.emit()"
      (click)="toggleActions()"
      (click)="logEventTitleUpdateProfilePicture()"
    >
      <i class="m-icon va-m">
        account_circle
      </i>
      <a class="nav-link" [attr.data-au]="'update-profile-picture'">
        Update profile picture
      </a>
    </li>
    <ng-container *ngIf="hasClientAdministratorPermission || notificationSettingsPermission">
      <li
        class="nav-li"
        [routerLinkActive]="['active']"
        [routerLinkActiveOptions]="{match:['/settings']}"
        (click)="navigate(['settings']); toggleActions()"
        (click)="logEventSettings()">
        <i class="m-icon va-m">
          settings
        </i>
        <a class="nav-link" [attr.data-au]="'settings'">
          Settings
        </a>
      </li>
    </ng-container>
    <ng-container *ifFeatureFlag="FEATURE_FLAG.INVOICES">
      <li
        class="nav-li"
        [routerLinkActive]="['active']"
        [routerLinkActiveOptions]="{match:['/account/invoices']}"
        (click)="navigate(['account/invoices']); toggleActions()"
        (click)="logEventTitleInvoices()">
        <i class="m-icon va-m">
          receipt
        </i>
        <a class="nav-link" [attr.data-au]="'invoices'">
          Invoices
        </a>
      </li>
    </ng-container>
    <ng-container *ifFeatureFlag="FEATURE_FLAG.STAFF_MANAGEMENT">
      <li
        class="nav-li"
        *ngIf="hasClientAdministratorPermission"
        [routerLinkActive]="['active']"
        [routerLinkActiveOptions]="{match:['/account/users']}"
        (click)="navigate(['/account/users']); toggleActions()"
        (click)="logEventTitleManageStaff()">
        <i class="m-icon va-m">
          group_add
        </i>
        <a class="nav-link" [attr.data-au]="'manage-users'">
          Manage Users
        </a>
      </li>
    </ng-container>
    <li class="nav-li" (click)="logout.emit(); toggleActions()" (click)="logEventTitleLogout()">
      <i class="m-icon va-m">
        exit_to_app
      </i>
      <a class="nav-link" [attr.data-au]="'logout'">
        Logout
      </a>
    </li>
  </ul>
</div>
