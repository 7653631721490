export class RouteAnchor {
    name: string;
    activationFn: Function;
    activationParam: any;
    subroutes: string[] = [];

    constructor(
        _name: string,
        _activationParam: any,
        _activationFn: Function,
        ...args
    ) {
        this.name = _name;
        this.activationFn = _activationFn;
        this.activationParam = _activationParam;
        this.subroutes = args;
    }

    navigate() {
        this.activationFn(this.activationParam);
    }
}
