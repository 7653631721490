export enum WeekDayType {
    NotSet = -1,
    Full = 0,
    AM = 1,
    PM = 2
}

export const WeekMap = {
    0: 'SUN',
    1: 'MON',
    2: 'TUE',
    3: 'WED',
    4: 'THU',
    5: 'FRI',
    6: 'SAT'
}

export class WeekDay {
    private _dayOfWeek: number = 0;
    private _type: WeekDayType = WeekDayType.Full;

    set Type(value: WeekDayType) {
        this._type = value;
    }

    get Type() {
        return this._type;
    }

    get Name() {
        return WeekMap[this._dayOfWeek];
    }

    get DayOfWeek() {
        return this._dayOfWeek;
    }

    constructor(dayOfWeek: number, type: WeekDayType) {
        this._dayOfWeek = dayOfWeek;
        this._type = type;
    }

    deserialize(input: any): WeekDay {
        if (typeof input === 'undefined' || input === null) {
          return this;
        }
    
        Object.keys(input)
          .forEach(key => {
            if (typeof input[key] !== 'undefined') {
              this[key] = input[key];
            }
        });

        return this;
    }
}