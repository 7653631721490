import { AfterContentInit, Component, ContentChild, ElementRef, Input } from '@angular/core';

@Component({
  selector: 'app-tab',
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.scss']
})
export class TabComponent  {
  //@Input('tabTitle') title: string;
  @Input('tabIcon') icon: string = '';
  @Input() active = false;
  @Input() tabIndex: number;

  @Input() tabTitle: ElementRef<any>;

}
