import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LocalizeDatePipe } from './pipes/localize-date.pipe';
import { LocalizedDateFormatProvider }from './providers/localized-date-format.provider'
import { LocalizedTextProvider } from './providers/localized-text.provider';
import { LocalizedUrlProvider } from './providers/localized-url.provider';
import { LocalizeTextPipe } from './pipes/localize-text.pipe';
import { LocalizedFeatureFlagsService } from './services/localized-feature-flags.service';
import { LocalizeUrlPipe } from './pipes/localize-url.pipe';

@NgModule({
  declarations: [
    LocalizeDatePipe,
    LocalizeTextPipe,
    LocalizeUrlPipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    LocalizeDatePipe,
    LocalizeTextPipe,
    LocalizeUrlPipe
  ],
  providers: [
    LocalizedDateFormatProvider,
    LocalizedTextProvider,
    LocalizedUrlProvider,
    LocalizedFeatureFlagsService
  ]
})
export class LocalizationModule { }
