import { EventEmitter, Injectable } from '@angular/core';
import { AuthService, RegistrationService } from '@app/modules/_shared';
import { environment } from 'environments/environment';
import Pubnub from 'pubnub';

export type NotificationType = 'allOffersDeclined' | 'candidateAccepted' | 'candidateArrived' | 'candidateDeclined' |
                        'offerExpired' | 'candidateRunningLate' | 'timesheetReadyForSignOff' | 'reviewReminder';
export enum NotificationIcon {
  Warning = 0,
  Ok = 1,
  Review = 2
}

export interface NotificationConfig {
  icon: NotificationIcon;
  link: string;
}

const JobAcceptedDelay: number = 5000;

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  public RefreshNotificationEvent: EventEmitter<any>;
  public JobAcceptedEvent : EventEmitter<any>;
  public notificationsConfig: any; 
  notifications: any[] = [];
  channel : string;
  pubnub: Pubnub;

  constructor(
    private registrationService: RegistrationService,
    private auth: AuthService
  ) { 

    this.RefreshNotificationEvent = new EventEmitter<any>();
    this.JobAcceptedEvent = new EventEmitter<any>();
    this.initializeNotificationsConfig();

    this.auth.LogInEvent.subscribe(() => {
      this.initializePubnub();
    });

    this.auth.LogOutEvent.subscribe(() => {
      this.pubnub?.unsubscribe({ channels: [this.channel] });
      this.channel = null;
    });
  }

  private initializePubnub() {
    this.registrationService.getLoggedInUserDetails().subscribe(
      result => {
        this.pubnub = new Pubnub({
          userId: result.MemberId,
          subscribeKey: environment.pubNub.subscribeKey,
          publishKey: environment.pubNub.publishKey
        });

        this.pubnub.addListener({
          message: (message: any) => {
            if (message != undefined && message.channel === this.channel && message.message != undefined) {
              this.notifications = [message, ...this.notifications];
              this.RefreshNotificationEvent.emit();
              if (message.message.Type === 'candidateAccepted') {
                setTimeout(() => { this.JobAcceptedEvent.emit(); }, JobAcceptedDelay);
              }
            }
          }
        });

        this.initializeNotificationsChannel(result.MemberId);
      },
      error => {
        console.log(error);          
      }
    )
  }

  private initializeNotificationsChannel(memberId: string = '') {
    if (!memberId)
    {
      this.registrationService.getLoggedInUserDetails().subscribe(
        result => {
          this.channel = "client_notifications_" + result.MemberId;
          this.pubnub.subscribe({ channels: [this.channel] });
          this.getNotifications();
        },
        error => {
          console.log(error)
        }
      )
    }
    else {
      this.channel = "client_notifications_" + memberId;
      this.pubnub.subscribe({ channels: [this.channel] });
      this.getNotifications();
    }
  }

  initializeNotificationsConfig() {
    let notificationsConfig = new Map<NotificationType, NotificationConfig>();
    notificationsConfig.set( 'allOffersDeclined', { icon: NotificationIcon.Warning, link: '/manage-jobs' });
    notificationsConfig.set( 'candidateAccepted', { icon: NotificationIcon.Ok, link: '/manage-jobs' });
    notificationsConfig.set( 'candidateArrived', { icon: NotificationIcon.Ok, link: '/manage-jobs' });
    notificationsConfig.set( 'candidateDeclined', { icon: NotificationIcon.Warning, link: '/manage-jobs' });
    notificationsConfig.set( 'candidateRunningLate', { icon: NotificationIcon.Warning, link: '/manage-jobs' });
    notificationsConfig.set( 'offerExpired', { icon: NotificationIcon.Warning, link: '/manage-jobs' });
    notificationsConfig.set( 'timesheetReadyForSignOff', { icon: NotificationIcon.Ok, link: '/manage-jobs/completedjobs' });
    notificationsConfig.set( 'reviewReminder', { icon: NotificationIcon.Review, link: '/reviews'})
    this.notificationsConfig = notificationsConfig;
  }

  getNotifications() {
    let channelId = this.channel;
    this.pubnub.fetchMessages(
      {
        channels: [channelId],
      },
      (status: any, response: any) =>
      {
        if (response.channels[channelId]) {
          this.notifications = response.channels[channelId].reverse();
          this.RefreshNotificationEvent.emit();          
        }
      }
    );
  }

  deleteNotification(notification: any) {

    let start: number = Number(notification.timetoken);
    start++;

    this.pubnub.deleteMessages(
      {
          channel: this.channel,
          start: start.toString(),
          end: notification.timetoken
      },
      (result) => {
          if (result.error === true) {
            console.log(result);
          }
      }
    );

    this.notifications = this.notifications.filter( n => n.timetoken .timetoken !== notification.timetoken);
    this.RefreshNotificationEvent.emit();

  }

  deleteAllNotifications() {
    
    let start: number = Number(this.notifications[0]. timetoken);
    start -= 100;

    this.pubnub.deleteMessages(
      {
          channel: this.channel,
          start: start.toString(),
          end: this.notifications[this.notifications.length - 1].timetoken
      },
      (result) => {
          if (result.error === true) {
            console.log(result);
          }
      }
    );

    this.notifications = [];
    this.RefreshNotificationEvent.emit();
  }

}
