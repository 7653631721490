import { Pipe, PipeTransform } from '@angular/core';
import { Dayjs } from 'dayjs';

/* Custom parsing formats*/
export type Day = 'day';
export type Date = 'date';
export type AvailabilityDayFormat = Day | Date;

@Pipe({
  name: 'availabilityDay'
})
export class AvailabilityDayPipe implements PipeTransform {

  transform(value: Date | string | Dayjs, format: AvailabilityDayFormat = 'date'): string {
    return null;
  }

}
