<div class="row d-flex">
  <div class="col text-container" [ngClass]="{'uncompleted': isNotValid && !reference.AdditionalNotes}">
    <div class="py-3 referee-relation" *ngIf="disabled">
      <input type="text" [value]="reference.JobTitle" disabled>
    </div>
    <textarea id="characterReferenceNotes" name="characterReferenceNotes" maxlength="500"
      [placeholder]="disabled ? '' : 'Please write a few sentences describing ' + reference.Candidate.FullName" 
      [(ngModel)]="reference.AdditionalNotes" [disabled]="disabled" (keyup)="!disabled && countTextLength($event)"></textarea>
      <span id="text-length" *ngIf="!disabled">{{textLength}}/500</span>
  </div>
</div>

<!-- Confirmation -->
<div class="row confirm mx-0" *ngIf="!disabled">
  <div class="col-12">
      <h2 class="text-center">Confirm reference</h2>
      <p class="mb-4">
        I, <strong>{{ reference.FirstName }}</strong>, can confirm that I have known <strong>{{ reference.Candidate.FullName }}</strong> from <strong>{{ reference.DateFrom | date : "dd MMMM yyyy"}}</strong>,
        and the information I have supplied above is accurate.
      </p>
      
      <div class="custom-control custom-checkbox mb-2">
          <input class="custom-control-input" id="accepted_01" type="checkbox" [(ngModel)]="reference.AgreeShareWithCandidate" [disabled]="disabled">
          <label class="custom-control-label" for="accepted_01">I am happy to share this reference with {{ reference.Candidate.FullName }} if they request.</label>
      </div>
          
      <div class="custom-control custom-checkbox">
          <input class="custom-control-input" id="accepted_02" type="checkbox" [(ngModel)]="reference.AgreeShareWithSchools" [disabled]="disabled">
          <label class="custom-control-label" for="accepted_02">I am happy to share this reference with other schools  /  institutes if requested.</label>
      </div>

      <p class="uncompleted text-center" [ngClass]="{'d-block': isNotValid }">Please answer the questions highlighted in red above</p>

      <p class="uncompleted text-center" [ngClass]="{'d-block': submissionError }">Something went wrong, please try again later.</p>

      <button class="btn btn-primary col-12 mb-3" (click)="updateReference()" name="ie11sux" value="Submit" >Confirm & submit <span [ngClass]="{'loader right': submitLoader}"></span></button>
      <p class="mb-0 text-center">Opogo trading under Thames Teachers</p>
  </div>
</div>

<div class="row confirm confirmation--statement mx-0" *ngIf="showConfirmationStatement()">
  <div class="col-12 text-center">
      <h2>Confirmation statement</h2>
      <p>Character reference was provided on <strong>{{ reference.CreatedOn | date : "dd MMMM yyyy" }}</strong> by <strong>{{ reference.FirstName }} </strong>
        who has known them since <strong>{{ reference.DateFrom | date : "dd MMMM yyyy" }}</strong>
      </p>
      <p class="mb-0 pb-0">Opogo trading under Thames Teachers</p>
  </div>
</div>