export interface UserSetting {
  Id: number;
  UserId: string;
  UserSettingTypeId: number;
  SettingName: string;
  Type?: UserSettingType;
  Value: string;
}

export enum UserSettingType {
  AllowJobStatusEmailNotifications = "5",
  AllowTalentPoolEmailNotifications = "6",
  AllowJobStatusSMSNotifications	= "7",
  AllowCandidateSummaryEmails = "12",
  AllowRecommendedTalentEmails = "13",
  AllowReviewSummaryEmails = "14"
}

export interface ClientSetting {
  Id: number;
  ClientId: string;
  ClientSettingTypeId: number;
  SettingName: string;
  Type?: ClientSettingType;
  Value: string;
}

export enum ClientSettingType {
  AllowCandidateDirectChat = "1"
}

