<div class="row controll-grid d-none">
  <div class="col-6 col-md-4 col-lg" *ngIf="hasJobBookingPermission">
    <a routerLink="/add-job" class="bg-gray-light br-wrap">
      <span class="li_heart"></span>
      <h6>Add job</h6>
    </a>
  </div>
  <div class="col-6 col-md-4 col-lg" *ngIf="hasJobBookingPermission">
    <a routerLink="/manage-jobs" class="bg-gray-light br-wrap no-highlight">
      <span class="li_cloud"></span>
      <h6>Manage jobs</h6>
    </a>
  </div>
  <div class="col-6 col-md-4 col-lg" *ngIf="hasJobCheckingInPermission">
    <a class="bg-gray-light br-wrap disabled no-highlight">
      <span class="li_camera"></span>
      <h6>Check-in</h6>
    </a>
  </div>
  <div class="col-6 col-md-4 col-lg" *ngIf="hasContentViewingPermission">
    <a
      href="{{ joinCommunityLink }}"
      target="_blank"
      class="bg-gray-light br-wrap no-highlight"
    >
      <span class="li_user"></span>
      <h6>Learn</h6>
    </a>
  </div>
  <div class="col-6 col-md-4 col-lg" *ngIf="hasJobSigningOffPermission">
    <a routerLink="/manage-jobs/completedjobs" class="bg-gray-light br-wrap no-highlight">
      <span class="li_note"></span>
      <h6>Sign-off timesheets</h6>
    </a>
  </div>
</div>
