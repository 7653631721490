import { BookingStartTimes } from "@app/interfaces";
import { TagCategory, TagTree } from "./tag.model";
import dayjs, { Dayjs } from "dayjs";
import { Day } from "@app/pipes/availability-day.pipe";

export class JobRoleGroup {
  Id: number = null;
  Name: string = null;
  OrderNo: number = null;
  TagCategories: TagTree = null;
}

export enum ClubType {
  Before = 1,
  Mid = 2,
  After = 3
}

export enum QuickSelector {
  Never = 0,
  WithPermissions = 1,
  Always = 2
}

export class JobRole {
  Id: string = null;
  Name: string = null;
  RoleId: string = null;
  QuickSelector: QuickSelector = QuickSelector.Never;
  ClubType: ClubType | null = null;

  get IsQuickSelect() {
    return this.QuickSelector !== QuickSelector.Never;
  }

  get IsClubRole() {
    return this.ClubType !== null;
  }

  get IsClubTypeBefore() {
    return this.ClubType === ClubType.Before;
  }

  get IsClubTypeMid() {
    return this.ClubType === ClubType.Mid;
  }

  get IsClubTypeAfter() {
    return this.ClubType === ClubType.After;
  }

  getStartTimeByClubType(startTimes: BookingStartTimes): Dayjs {
    if (!this.ClubType) {
      return dayjs(startTimes.Morning, 'hh:mm');
    }

    if (this.ClubType === ClubType.Before) {
      return dayjs(startTimes.StartTimeClubBefore, 'hh:mm');
    }

    if (this.ClubType === ClubType.Mid) {
      return dayjs(startTimes.StartTimeClubMid, 'hh:mm');
    }

    return dayjs(startTimes.StartTimeClubAfter, 'hh:mm');
  }
  
  deserialize(input: any) {
    if (typeof input === 'undefined' || input === null) {
      return this;
    }

    Object.keys(this).forEach(key => {
      this[key] = input[key];
    });
    
    return this;
  }
}

export class DetailedTagCategory extends TagCategory {
  RoleGroups: number[] = [];
  JobRoles: string[] = [];
}