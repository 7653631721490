<div class="container">
  <div class="row align-items-center justify-content-end">
    <div class="col-auto pr-1">
      <a class="btn btn-link" *ngIf="!loggedIn" href="{{opogoService.signinUrl}}" target="_top">Sign in</a>
      <button class="btn btn-link" *ngIf="loggedIn" (click)="signOut()">Sign out</button>
    </div>
    <div class="col-auto pl-1">
      <a class="btn btn-primary" *ngIf="!loggedIn" href="{{opogoService.signupUrl}}" target="_top">Sign up</a>
      <div class="mb-0" *ngIf="userDetails && loggedIn">Hello {{ userDetails?.FirstName }}</div>
    </div>    
  </div>
</div>