import { Pipe, PipeTransform } from '@angular/core';
import { LocalizeDatePipe } from '@app/localization/pipes/localize-date.pipe';
import { DateFormat } from '@app/localization/providers/localized-date-format.provider';
import dayjs from 'dayjs';
import { Dayjs } from 'dayjs';

@Pipe({
  name: 'lastMessageDate'
})
export class LastMessageDatePipe implements PipeTransform {

  constructor(private localizeDatePipe: LocalizeDatePipe) { }

  transform(value: string | Date | Dayjs, args?: any): any {
    let messageDate = dayjs(value);

    if (!messageDate.isValid()) {

      return null;
    }

    let today = dayjs();

    if (messageDate.utc(true).isSame(today, 'day')) {
      return "Today";
    }

    return  this.localizeDatePipe.transform(value, DateFormat.DateShortDayName);
  }

}
