import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-circular-progress',
  templateUrl: './circular-progress.component.html',
  styleUrls: ['./circular-progress.component.scss']
})
export class CircularProgressComponent implements OnInit, AfterViewInit {
  @Input() percent: number;
  @Input() text: string;
  @Input() size: string;
  @Input() textSize: string;
  @Input() color: string;

  @ViewChild('progress') progress: ElementRef;
  @ViewChild('content') content: ElementRef;
  @ViewChild('left') left: ElementRef;
  @ViewChild('right') right: ElementRef;

  constructor() { }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    let leftSpan = this.left.nativeElement as HTMLSpanElement;
    leftSpan.style.color = this.color;

    let rightSpan = this.right.nativeElement as HTMLSpanElement;
    rightSpan.style.color = this.color;

    let contentDiv  = this.content.nativeElement as HTMLDivElement;
    contentDiv.style.fontSize = this.textSize;

    let progressDiv = this.progress.nativeElement as HTMLDivElement;
    progressDiv.style.height = this.size;
    progressDiv.style.width = this.size;
  
    if (this.percent > 0) {
      if (this.percent <= 50) {
        rightSpan.style.transform = 'rotate(' + this.percentageToDegrees(this.percent) + 'deg)';
      } else {
        rightSpan.style.transform = 'rotate(180deg)';
        leftSpan.style.transform = 'rotate(' +this. percentageToDegrees(this.percent - 50) + 'deg)';
      }
    }
  }

  percentageToDegrees(percentage: number) {
    return percentage / 100 * 360
  }

}
