import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-outside-img-container',
  templateUrl: './outside-img-container.component.html',
  styleUrls: ['./outside-img-container.component.scss']
})
export class OutsideImgContainerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
