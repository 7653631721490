import dayjs from 'dayjs';

export enum ECalendarValue {
  DayJs = 1,
  DayJsArr = 2,
  String = 3,
  StringArr = 4
}

export type CalendarValue = dayjs.Dayjs | dayjs.Dayjs[] | string | string[];
export type CalendarMode = 'day' | 'month' | 'daytime' | 'time';
export type SingleCalendarValue = dayjs.Dayjs | string;

export type DateValidator = (inputVal: CalendarValue) => {[key: string]: any};


export interface IDate {
  date: dayjs.Dayjs;
  selected: boolean;
}

export interface ICalendar {
  locale?: string;
  min?: SingleCalendarValue;
  max?: dayjs.Dayjs | string;
}

export interface ICalendarInternal {
  locale?: string;
  min?: dayjs.Dayjs;
  max?: dayjs.Dayjs;
}

export interface IConfig {
  hours12Format?: string;
  hours24Format?: string;
  maxTime?: dayjs.Dayjs;
  meridiemFormat?: string;
  minTime?: dayjs.Dayjs;
  minutesFormat?: string;
  minutesInterval?: number;
  secondsFormat?: string;
  secondsInterval?: number;
  showSeconds?: boolean;
  showTwentyFourHours?: boolean;
  timeSeparator?: string;
  returnedValueType?: ECalendarValue;
}

export interface ITimeSelectConfig extends IConfig, ICalendar {
}

export interface ITimeSelectConfigInternal extends IConfig,
  ICalendarInternal {
}

export interface DateLimits {
  minDate?: SingleCalendarValue;
  maxDate?: SingleCalendarValue;
  minTime?: SingleCalendarValue;
  maxTime?: SingleCalendarValue;
}
