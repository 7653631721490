import {
  Component,
  ContentChildren,
  QueryList,
  AfterContentInit,
  Input,
  SimpleChanges
} from '@angular/core';
import { TabComponent } from './tab.component';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss']
})
export class TabsComponent implements AfterContentInit {
  @Input() selectedTabIndex: number;
  @Input() onClickTab: (tabTitle: string | number) => void;
  @Input() tabsHeaderClass: string = '';
  @Input() tabsBodyClass: string = '';
  @Input() showSelectedTabTitle: boolean = false;
  @Input() showTabColorDivider: boolean = false;

  @ContentChildren(TabComponent) tabs: QueryList<TabComponent>;
  
  ngAfterContentInit() {
    let activeTabs = this.tabs.filter((tab)=>tab.active);
    if(activeTabs.length === 0) {
      this.selectTab(this.tabs.toArray()[this.selectedTabIndex]);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.tabs && changes.selectedTabIndex && changes.selectedTabIndex.previousValue !== changes.selectedTabIndex.currentValue) {
      this.selectTab(this.tabs.toArray()[this.selectedTabIndex]);
    }
  }
  
  selectTab(tab: any){
    if (!tab) {
      return;
    }
    
    this.tabs.toArray().forEach(tab => tab.active = false);  
    tab.active = true;

    this.onClickTab(tab.tabIndex);
  }
}
