import { CommonModule } from '@angular/common';
import { Component, TemplateRef } from '@angular/core';
import { ClientDataStoreService } from '@app/services/client-data-store.service';
import { ClientSelectDropdown, ClientTree } from '../../client-select-dropdown';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { filter } from 'rxjs';

@Component({
  selector: 'app-client-select-dropdown-mobile',
  standalone: true,
  imports: [
    CommonModule
  ],
  templateUrl: './client-select-dropdown-mobile.component.html',
  styleUrl: './client-select-dropdown-mobile.component.scss'
})
export class ClientSelectDropdownMobileComponent extends ClientSelectDropdown {
  checkedClientId: string;
  
  modalRef: BsModalRef | null;

  set SelectedClient(value: ClientTree) {
    this.selectedClient = value;
    this.checkedClientId = value.Id;
  }

  constructor(
    clientStore: ClientDataStoreService,
    private modalService: BsModalService) {
    super(clientStore);

    this.subscriptions$.push(
      clientStore.clientTree$
      .pipe(filter(client => !!client))
      .subscribe(client => {
        client.Expanded = true;
        
        this.mainClient = client;
        this.SelectedClient = client;
        
        this.subscriptions$.push(clientStore.clientSummary$.subscribe(clientSummary => {
          if (clientSummary && clientSummary.ClientId !== this.selectedClient.Id) {
            this.SelectedClient = this.getClientFromTreeById(this.mainClient, clientSummary.ClientId, true);
          }
        }));
      }))
  }

  onClientClicked(client: ClientTree) {
    if (client.Clients.length === 0) {
      this.checkedClientId = client.Id;

      return;
    }

    client.Expanded = !client.Expanded;
  }

  onSwitchAccountClicked() {
    let client = this.getClientFromTreeById(this.mainClient, this.checkedClientId);
    this.selectClient(client);
    this.closeClientSelectModal()
  }

  getSelectedClientParent(clientTree: ClientTree) {
    if (this.mainClient.Clients.some(c => c.Id === this.selectedClient.Id)) {
      return this.mainClient;
    }

    for (let i = 0; i < clientTree.Clients.length; i++) {
      let selectedClientSubGroup = this.getSelectedClientParent(clientTree.Clients[i]);

      if (selectedClientSubGroup) {
        return selectedClientSubGroup;
      }
    }

    return null;
  }

  openClientSelectModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, {...{class: 'select-modal modal-full full-width mobile'}});
  }

  closeClientSelectModal(){
    this.modalRef?.hide();
    this.modalRef = null;
  }
}
