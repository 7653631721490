import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { ErrorPageComponent } from './components/error-page/error-page.component';
import { LoginComponent } from './components/auth-pages/login/login.component';
import { ForgotPasswordComponent } from './components/auth-pages/forgot-password/forgot-password.component';
import { ForgotPasswordEmailConfirmationComponent } from './components/auth-pages/forgot-password-email-confirmation/forgot-password-email-confirmation.component';
import { PasswordResetSuccessfulComponent } from './components/auth-pages/password-reset-successful/password-reset-successful.component';
import { ChangePasswordComponent } from './components/auth-pages/change-password/change-password.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { ReferenceComponent } from './components/reference/reference.component';
import { TutorialsComponent } from './components/tutorials/tutorials.component';
import { SorryPageComponent } from './components/auth-pages/sorry-page/sorry-page.component';

// embedded sig-up compontnents
import { SignUpComponent } from './components/embedded-forms/sign-up/sign-up.component';
import { ValidateEmailComponent } from './components/embedded-forms/validate-email/validate-email.component';
import { EmbeddedSignInComponent } from './components/embedded-forms/embedded-sign-in/embedded-sign-in.component';
import { EmbeddedSigninPanelComponent } from './components/embedded-forms/embedded-signin-panel/embedded-signin-panel.component';
import { SetPasswordComponent } from './components/embedded-forms/set-password/set-password.component';
import { EmbeddedForgottenPasswordComponent } from './components/embedded-forms/embedded-forgotten-password/embedded-forgotten-password.component';
import { SignedInComponent } from './components/embedded-forms/signed-in/signed-in.component';
import { SignoutComponent } from './components/embedded-forms/signout/signout.component';

/* Guards */
import { AuthGuard, authLoginGuard} from './guards';
import { UserInfoResolver, ExpertsResolver, LoginResolver, RoleConfigResolver } from './resolvers';
import { NgModule } from '@angular/core';
import { ClientTermsResolver } from './resolvers/client-terms.resolver';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [authLoginGuard],
    data: {
      hideShell: true
    }
  },
  {
    path: 'sorry',
    component: SorryPageComponent,
    data: {
      hideShell: true
    }
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
    data: {
      hideShell: true
    }
  },
  {
    path: 'forgot-password-email-confirmation',
    component: ForgotPasswordEmailConfirmationComponent,
    data: {
      hideShell: true
    }
  },
  {
    path: 'change-password',
    component: ChangePasswordComponent,
    data: {
      hideShell: true
    }
  },
  {
    path: 'resetpassword',
    component: ChangePasswordComponent,
    data: {
      hideShell: true
    }
  },
  {
    path: 'resetpasswordsuccessful',
    component: PasswordResetSuccessfulComponent,
    data: {
      hideShell: true
    }
  },
  {
    path: 'reference/:id',
    component: ReferenceComponent
  },
  {
    path: 'reference/:id/:key',
    component: ReferenceComponent
  },
  // embedded sign-up start
  {
    path: 'signup',
    component: SignUpComponent
  },
  {
    path: 'confirmemail',
    component: ValidateEmailComponent
  },
  {
    path: 'embeddedsignin',
    component: EmbeddedSignInComponent
  },
  {
    path: 'embeddedsigninpanel',
    component: EmbeddedSigninPanelComponent
  },
  {
    path: 'set-password',
    component: SetPasswordComponent
  },
  {
    path: 'forgotten-password',
    component: EmbeddedForgottenPasswordComponent
  },
  {
    path: 'signedin',
    component: SignedInComponent
  },
  {
    path: 'signout',
    component: SignoutComponent
  },
  // embedded sign-up end
  {
    path: 'tutorials',
    component: TutorialsComponent,
    canActivate: [AuthGuard],
    resolve: {
      user: UserInfoResolver
    }
  },
  /* Lazy loaded feature modules here */
  {
    path: 'add-job',
    loadChildren: () => import('app/modules/addjobs/addjobs.module').then(x => x.AddjobsModule),
    canActivateChild: [AuthGuard],
    resolve: {
      user: UserInfoResolver,
      terms: ClientTermsResolver
    }
  },
  {
    path: 'manage-jobs',
    loadChildren: () => import('app/modules/managejobs/managejobs.module').then(x => x.ManageJobsModule),
    canActivateChild: [AuthGuard],
    resolve: {
      user: UserInfoResolver,
      terms: ClientTermsResolver
    }
  },
  {
    path: 'hub',
    loadChildren: () => import('app/modules/hub/hub.module').then(x => x.HubModule),
    canActivateChild: [AuthGuard],
    resolve: {
      user: UserInfoResolver,
      experts: ExpertsResolver
    }
  },
  {
    path: 'hub/manage-content',
    loadChildren: () => import('app/modules/managecontent/managecontent.module').then(x => x.ManageContentModule),
    canActivateChild: [AuthGuard],
    resolve: {
      user: UserInfoResolver
    }
  },
  {
    path: 'timesheets',
    loadChildren: () => import('app/modules/managejobs/managejobs.module').then(x => x.ManageJobsModule),
    canActivateChild: [AuthGuard],
    resolve: {
      user: UserInfoResolver,
      terms: ClientTermsResolver
    }
  },
  {
    path: 'talent',
    loadChildren: () => import('app/modules/talentpool/talentpool.module').then(x => x.TalentPoolModule),
    canActivateChild: [AuthGuard],
    resolve: {
      user: UserInfoResolver,
      terms: ClientTermsResolver
    },
  },
  {
    path: 'account',
    loadChildren: () => import('app/modules/account/account.module').then(x => x.AccountModule),
    canActivateChild: [AuthGuard],
    resolve: {
      user: UserInfoResolver
    }
  },
  {
    path: 'settings',
    loadChildren: () => import('app/modules/settings/settings.module').then(x => x.SettingsModule),
    canActivateChild: [AuthGuard],
    resolve: {
      user: UserInfoResolver
    }
  },
  {
    path: 'reviews',
    loadChildren: () => import('app/modules/reviews/reviews.module').then(x => x.ReviewsModule),
    canActivateChild: [AuthGuard],
    resolve: {
      user: UserInfoResolver
    }
  },

  /* End Lazy loaded */
  {
    path: '',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    data: {
      hideShell: false,
      componentKey: "DashboardComponent"
    },
    resolve: {
      user: UserInfoResolver
    }
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    data: {
      hideShell: false,
      componentKey: "DashboardComponent"
    },
    resolve: {
      user: LoginResolver
    }
  },
  {
    path: '**',
    component: ErrorPageComponent
  }
];


@NgModule({
  imports: [
    RouterModule.forRoot(
      routes,
      {
        scrollPositionRestoration: 'top',
        preloadingStrategy: PreloadAllModules,
      }
    )
  ],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule {
}
