import { Injectable } from '@angular/core';
import { ITimeSelectConfig, ITimeSelectConfigInternal, CalendarMode, ECalendarValue, CalendarValue, DateLimits } from './time-select.model';
import dayjs from 'dayjs';
import { DateValidator, SingleCalendarValue } from '@app/controls/time-select/time-select.model';

export type TimeUnit = 'hour' | 'minute' | 'second';
export const FIRST_PM_HOUR = 12;

@Injectable()
export class TimeSelectService {
  readonly DEFAULT_CONFIG: ITimeSelectConfigInternal = {
    hours12Format: 'hh',
    hours24Format: 'HH',
    meridiemFormat: 'A',
    minutesFormat: 'mm',
    minutesInterval: 1,
    secondsFormat: 'ss',
    secondsInterval: 1,
    showSeconds: false,
    showTwentyFourHours: false,
    timeSeparator: ':',
    locale: dayjs.locale()
  };

  constructor() {

  }

  getConfig(config: ITimeSelectConfig): ITimeSelectConfigInternal {
    const timeConfigs = {
      maxTime: this.onlyTime(config && config.maxTime),
      minTime: this.onlyTime(config && config.minTime)
    };

    const _config = <ITimeSelectConfigInternal>{
      ...this.DEFAULT_CONFIG,
      ...this.clearUndefined(config),
      ...timeConfigs
    };

    dayjs.locale(_config.locale);

    return _config;
  }

  getTimeFormat(config: ITimeSelectConfigInternal): string {
    return (config.showTwentyFourHours ? config.hours24Format : config.hours12Format)
      + config.timeSeparator + config.minutesFormat
      + (config.showSeconds ? (config.timeSeparator + config.secondsFormat) : '')
      + (config.showTwentyFourHours ? '' : ' ' + config.meridiemFormat);
  }

  getHours(config: ITimeSelectConfigInternal, t: dayjs.Dayjs | null): string {
    const time = t || dayjs();
    return time && time.format(config.showTwentyFourHours ? config.hours24Format : config.hours12Format);
  }

  getMinutes(config: ITimeSelectConfigInternal, t: dayjs.Dayjs | null): string {
    const time = t || dayjs();
    return time && time.format(config.minutesFormat);
  }

  getSeconds(config: ITimeSelectConfigInternal, t: dayjs.Dayjs | null): string {
    const time = t || dayjs();
    return time && time.format(config.secondsFormat);
  }

  getMeridiem(config: ITimeSelectConfigInternal, time: dayjs.Dayjs): string {
    return time && time.format(config.meridiemFormat);
  }

  decrease(config: ITimeSelectConfigInternal, time: dayjs.Dayjs, unit: TimeUnit): dayjs.Dayjs {
    let amount: number = 1;
    switch (unit) {
      case 'minute':
        amount = config.minutesInterval;
        break;
      case 'second':
        amount = config.secondsInterval;
        break;
    }
    return time.clone().subtract(amount, unit);
  }

  increase(config: ITimeSelectConfigInternal, time: dayjs.Dayjs, unit: TimeUnit): dayjs.Dayjs {
    let amount: number = 1;
    switch (unit) {
      case 'minute':
        amount = config.minutesInterval;
        break;
      case 'second':
        amount = config.secondsInterval;
        break;
    }

    return time.clone().add(amount, unit);
  }

  toggleMeridiem(time: dayjs.Dayjs): dayjs.Dayjs {
    if (time.hour() < FIRST_PM_HOUR) {
      return time.clone().add(12, 'hour');
    } else {
      return time.clone().subtract(12, 'hour');
    }
  }

  shouldShowDecrease(config: ITimeSelectConfigInternal, time: dayjs.Dayjs, unit: TimeUnit): boolean {
    if (!config.min && !config.minTime) {
      return true;
    }

    const newTime = this.decrease(config, time, unit);
    return (!config.min || config.min.isSameOrBefore(newTime))
      && (!config.minTime || config.minTime.isSameOrBefore(newTime));
  }

  shouldShowIncrease(config: ITimeSelectConfigInternal, time: dayjs.Dayjs, unit: TimeUnit): boolean {
    if (!config.max && !config.maxTime) {
      return true;
    }

    const newTime = this.increase(config, time, unit);
    return (!config.max || config.max.isSameOrAfter(newTime)) && (!config.maxTime || config.maxTime.isSameOrAfter(newTime));
  }

  shouldShowToggleMeridiem(config: ITimeSelectConfigInternal, time: dayjs.Dayjs): boolean {
    if (!config.min && !config.max && !config.minTime && !config.maxTime) {
      return true;
    }
    const newTime = this.toggleMeridiem(time);
    return (!config.max || config.max.isSameOrAfter(newTime))
      && (!config.min || config.min.isSameOrBefore(newTime))
      && (!config.maxTime || config.maxTime.isSameOrAfter(this.onlyTime(newTime)))
      && (!config.minTime || config.minTime.isSameOrBefore(this.onlyTime(newTime)));
  }

  onlyTime(m: dayjs.Dayjs): dayjs.Dayjs {
    return m && dayjs.isDayjs(m) && dayjs(m.format('HH:mm:ss'), 'HH:mm:ss');
  }

  clearUndefined<T>(obj: T): T {
    if (!obj) {
      return obj;
    }

    Object.keys(obj).forEach((key) => (obj[key] === undefined) && delete obj[key]);
    return obj;
  }

  createValidator({minDate, maxDate, minTime, maxTime}: DateLimits,
                  format: string,
                  calendarType: CalendarMode): DateValidator {
    let isValid: boolean;
    let value: dayjs.Dayjs[];
    const validators = [];
    const granularity = this.granularityFromType(calendarType);

    if (minDate) {
      const md = this.convertToDayJs(minDate, format);
      validators.push({
        key: 'minDate',
        isValid: () => {
          const _isValid = value.every(val => val.isSameOrAfter(md, granularity));
          isValid = isValid ? _isValid : false;
          return _isValid;
        }
      });
    }

    if (maxDate) {
      const md = this.convertToDayJs(maxDate, format);
      validators.push({
        key: 'maxDate',
        isValid: () => {
          const _isValid = value.every(val => val.isSameOrBefore(md, granularity));
          isValid = isValid ? _isValid : false;
          return _isValid;
        }
      });
    }

    if (minTime) {
      const md = this.onlyTime(this.convertToDayJs(minTime, format));
      validators.push({
        key: 'minTime',
        isValid: () => {
          const _isValid = value.every(val => this.onlyTime(val).isSameOrAfter(md));
          isValid = isValid ? _isValid : false;
          return _isValid;
        }
      });
    }

    if (maxTime) {
      const md = this.onlyTime(this.convertToDayJs(maxTime, format));
      validators.push({
        key: 'maxTime',
        isValid: () => {
          const _isValid = value.every(val => this.onlyTime(val).isSameOrBefore(md));
          isValid = isValid ? _isValid : false;
          return _isValid;
        }
      });
    }

    return (inputVal: CalendarValue) => {
      isValid = true;

      value = this.convertToDayJsArray(inputVal, format, true).filter(Boolean);

      if (!value.every(val => val.isValid())) {
        return {
          format: {
            given: inputVal
          }
        };
      }

      const errors = validators.reduce((map, err) => {
        if (!err.isValid()) {
          map[err.key] = {
            given: value
          };
        }

        return map;
      }, {});

      return !isValid ? errors : null;
    };
  }

  granularityFromType(calendarType: CalendarMode): dayjs.UnitType {
    switch (calendarType) {
      case 'time':
        return 'second';
      case 'daytime':
        return 'second';
      default:
        return calendarType;
    }
  }

  convertToDayJs(date: SingleCalendarValue, format: string): dayjs.Dayjs {
    if (!date) {
      return null;
    } else if (typeof date === 'string') {
      return dayjs(date, format);
    } else {
      return date.clone();
    }
  }

  convertFromDayJsArray(format: string,
                         value: dayjs.Dayjs[],
                         convertTo: ECalendarValue): CalendarValue {
    switch (convertTo) {
      case (ECalendarValue.String):
        return value[0] && value[0].format(format);
      case (ECalendarValue.StringArr):
        return value.filter(Boolean).map(v => v.format(format));
      case (ECalendarValue.DayJs):
        return value[0] ? value[0].clone() : value[0];
      case (ECalendarValue.DayJsArr):
        return value ? value.map(v => v.clone()) : value;
      default:
        return value;
    }
  }

  getInputType(value: CalendarValue, allowMultiSelect: boolean): ECalendarValue {
    if (Array.isArray(value)) {
      if (!value.length) {
        return ECalendarValue.DayJsArr;
      } else if (typeof value[0] === 'string') {
        return ECalendarValue.StringArr;
      } else if (dayjs.isDayjs(value[0])) {
        return ECalendarValue.DayJsArr;
      }
    } else {
      if (typeof value === 'string') {
        return ECalendarValue.String;
      } else if (dayjs.isDayjs(value)) {
        return ECalendarValue.DayJs;
      }
    }

    return allowMultiSelect ? ECalendarValue.DayJsArr : ECalendarValue.DayJs;
  }

  convertToDayJsArray(value: CalendarValue, format: string, allowMultiSelect: boolean): dayjs.Dayjs[] {
    switch (this.getInputType(value, allowMultiSelect)) {
      case (ECalendarValue.String):
        return value ? [dayjs(<string>value, format, true)] : [];
      case (ECalendarValue.StringArr):
        return (<string[]>value).map(v => v ? dayjs(v, format, true) : null).filter(Boolean);
      case (ECalendarValue.DayJs):
        return value ? [(<dayjs.Dayjs>value).clone()] : [];
      case (ECalendarValue.DayJsArr):
        return (<dayjs.Dayjs[]>value || []).map(v => v.clone());
      default:
        return [];
    }
  }
}
