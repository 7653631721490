import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError, EMPTY } from 'rxjs';

import { environment } from '../../environments/environment';
import { AuthService } from '@services';
import { catchError, map, tap } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private BASE_URL: string = environment.apiBaseURL;

  private unauthorisedCount: number = 0;
  private unauthorisedMax: number = 5;

  constructor(
    private router: Router,
    private auth: AuthService
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const excludedUrl = [
      `${this.BASE_URL}/contents/PartnerUploadMedia`,
      `${this.BASE_URL}/staffmanagement/bulkImportStaff`,
      `${this.BASE_URL}/clients/reference`,
      `${this.BASE_URL}/candidate/profile/image/update`,
      `${this.BASE_URL}/candidate/UploadCV`
    ];

    if (excludedUrl.some(x => req.url.startsWith(x))) {
      return next.handle(req);
    }

    if (this.auth.token !== null) {

      const headers = new HttpHeaders({
        'Authorization': `bearer ${this.auth.token}`,
        'Content-Type': 'application/json',
        'Api-Version': '1.0'
      });

      req = req.clone({headers});
    }

    return next.handle(req).pipe(tap(
      (event: HttpEvent<any>) => event,
      (error: any) => {
        if (error instanceof HttpErrorResponse) {
          return this.handleError(error, req, next);
        }
      }
    ));
  }

  private handleError(error: Response | any, req: HttpRequest<any>, next: HttpHandler) {
    if (error.status === 401) {
      this.unauthorisedCount++;

      if (this.unauthorisedCount == this.unauthorisedMax) {
          this.auth.logout();
          this.unauthorisedCount = 0;
          return EMPTY;
      } 

      return this.auth.refreshToken(this.auth.refToken).pipe(
        map((response) => {
          const responseJson = response.json();

          this.auth.pipeLogin(responseJson.access_token, responseJson.refresh_token, responseJson['.expires']);

          const headers = new HttpHeaders({
            'Authorization': `bearer ${this.auth.token}`,
            'Content-Type': 'application/json',
            'Api-Version': '1.0'
          });
    
          req = req.clone({headers});

          return next.handle(req);
        }),
        catchError(err => {
          return next.handle(req);
        })
      );
    }

    return next.handle(req);
  }

}
