import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ChatContact, ChatRoom } from '@app/interfaces';
import { ChatService } from '@app/services';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-chat-messages',
  templateUrl: './chat-messages.component.html',
  styleUrls: ['./chat-messages.component.scss']
})
export class ChatMessagesComponent implements OnInit, OnDestroy {
  @Input() index: number = 0;

  subscriptions: Subscription[] = [];
  messageText: string;

  @ViewChild('chatHistory') chatHistory: ElementRef;

  constructor(private chatService: ChatService) { }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

  ngOnInit() {
    const newChatRoomSubscription = this.chatService.NewChatRoomSelected.subscribe((chatRoom?: ChatRoom) => {
      if (!this.isHidden()) {
        this.messageText = '';
        this.scrollToBottom();
      }
    });

    this.subscriptions.push(newChatRoomSubscription);

    const chatRoomExpandedSubscription = this.chatService.ChatRoomExpanded.subscribe((chatRoom?: ChatRoom) => {
      this.scrollToBottom();
    });

    this.subscriptions.push(chatRoomExpandedSubscription);

    const newMessageSubscription = this.chatService.NewMessageEvent.subscribe((message) => {  
      this.messageReceived(message);
    });

    this.subscriptions.push(newMessageSubscription);
  }

  getChatRoom() {
    return this.chatService.SelectedChatRooms[this.index].ChatRoom;
  }

  getMessages() {
    return this.chatService.SelectedChatRooms[this.index].Messages;
  }

  getIsExpanded() {
    if (this.isHidden()) {
      return false;
    }
    return this.chatService.SelectedChatRooms[this.index].Expanded;
  }

  messageReceived(message: any) {
    if (!this.isHidden() && this.getChatRoom().ChannelId === message.pn_other.chatChannel) {
      this.scrollToBottom();
    }
  }

  scrollToBottom() {
    setTimeout(() => { 
      this.chatHistory.nativeElement.scrollTop = this.chatHistory.nativeElement.scrollHeight;
    }, 100);
  }

  isDefaultPicture(imageUrl?: string) {
    return imageUrl === null ||imageUrl === "" || imageUrl.includes('default-member-photo.png');
  }

  getSenderProfilePicture(msg: any) {
    var userId = msg.message.pn_other.userId;
    var sender = this.getChatRoom().Participants.find(p => p.ContactId === userId || p.ContactUserId === userId);

    if (sender === null || sender === undefined) {
      return true;
    }
    else 
      return sender.ProfileImageUrl === null || sender.ProfileImageUrl === "" || sender.ProfileImageUrl.includes('default-member-photo.png');
  }

  getSenderContactName(msg: any) {
    var userId = msg.message.pn_other.userId;
    var sender = this.getChatRoom().Participants.find(p => p.ContactId === userId || p.ContactUserId === userId);

    if (sender === null || sender === undefined) {
      return msg.message.pn_other.ContactName;
    }
    else
      return sender.ContactName;
  }

  closeChatMessages() {
    this.chatService.unselectChatRoom(this.index);
  }

  getChatParticipant(userId: string): ChatContact {
   return this.getChatRoom().Participants.find(p => p.ContactId === userId || p.ContactUserId === userId);
  }

  onSendMessageKeyDown(event: KeyboardEvent) {
    if (event.key === 'Enter' && event.shiftKey === false) {
      this.sendMessage();
      event.preventDefault();
    }
  }

  sendMessage() {
    if (this.messageText != undefined && this.messageText.length > 0) {
      this.chatService.addUnreadMessage(this.getChatRoom().ChannelId, this.messageText).subscribe(
        result => {
          this.publishMessage(this.getChatRoom().ChannelId, this.messageText);
          this.messageText = '';
          this.scrollToBottom();
        }, 
        error => {
          console.log(error);
        }
      );
    }
  }

  publishMessage(channelId : string, message: string) {
    let chatRoom = this.getChatRoom();
    let senderName = chatRoom.Participants[1].ContactName;
    let badgeCount = 1;
    let senderEmail = chatRoom.Participants[1].ContactId;
    let senderProfileImage = chatRoom.Participants[1].ProfileImageUrl;

    var prim_message = {
      alert: 'New message from ' + senderName,
      badge: badgeCount,
      chatChannel: channelId,
      deleteMsg: false,
      image: senderProfileImage,
      message: message,
      school: false,
      timestamp: Math.floor(Date.now() / 1000),
      userId: senderEmail,
      userName: senderName
    };

    this.chatService.publishMessage(channelId, prim_message);
  }

  toogleExpanded() {
    this.chatService.expandChatRoom(this.index);
    this.scrollToBottom();
  }

  getPositionClass(): string {
    if (this.index === 2) {
      return ' third-tab';
    }

    if (this.index === 1) {
      if (this.chatService.SelectedChatRooms[0].Expanded) {
        return ' shifted-second-tab';
      } else {
        return ' second-tab';
      }
    }

    return '';
  }

  isHidden(): boolean {
    return this.chatService.SelectedChatRooms.length < this.index + 1;
  }

}
