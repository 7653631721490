export interface StaffPagingSortingFiltering {
  SortBy: StaffSortBy;
  SortOrder: StaffSortOrder;
  PageStartIndex: number;
  PageNumberOfRows: number;
  SearchTerm: string;
}

export interface Staff {
  Id: string;
  FirstName: string;
  LastName: string;
  EmailAddress: string;
  ProfilePicture: string;
  Title: string;
  FeatureAccess: any[];
  StatusMessage: string;
  StatusDate: Date;
  AccountActive: boolean;
  AccountIsAdmin: boolean;
}

export interface ClientStaffList {
  FilteredResults: Staff[];
  SortFilterPage: StaffPagingSortingFiltering;
  TotalStaff: number;
  FeaturesWithTotalStaff: any[];
}

export enum StaffSortBy {
  StaffName = 0,
  JobTitle = 1,
  FeatureAccess = 2,
  Status = 3
}

export enum StaffSortOrder {
  Ascending = 0,
  Descending = 1
}

export enum FeatureAccessType {
  TimesheetSignOff = 1,
  Financial = 2,
  Booker = 3,
  JobConfirmation = 4,
  HubCreator = 5
}
