<div class="container" *ngIf="!loggedIn">
  <div class="row justify-content-center">
    <div class="col-12 text-center">
      <h2>Sign in to Opogo</h2>      
  
      <form [formGroup]="loginForm" (ngSubmit)="onLogin()" novalidate class="loginForm">
        <div class="form-group">
          <input #opogousr type="email" formControlName="opogousr" placeholder="Enter your email address" required>
          <div *ngIf="!loginForm.get('opogousr').valid && loginForm.get('opogousr').touched && !loginForm.get('opogousr').pristine" class="error-msg">
            Please enter a valid email address.
          </div>
        </div>
        <div class="form-group">
          <input type="password" formControlName="opogopsw" placeholder="Enter your password" required>
          <div *ngIf="!loginForm.get('opogopsw').valid && loginForm.get('opogopsw').touched && !loginForm.get('opogopsw').pristine" class="error-msg">
            Incorrect password.
          </div>
        </div>
        <div *ngIf="serverError"  class="text-center error-msg">{{serverError}}</div>
        <button *ngIf="!returnUrl" type="submit" class="btn btn-primary mt-3">Sign in <span *ngIf="showLoader" class="loader right"></span></button>
      </form>
      <a *ngIf="returnUrl" type="submit" form="loginForm" (click)="onLogin()" target="_top" class="btn btn-primary mt-3">
        Sign in <span *ngIf="showLoader" class="loader right"></span>
      </a>
      <button class="btn btn-link" [routerLink]="['/forgotten-password']">Forgotten password?</button>
    </div>
  </div>
</div>

<div class="container welcome-back" *ngIf="loggedIn && !showLoader">
  <div class="row justify-content-center">
    <div class="col-12 text-center"><h2>Welcome back <br>You’re now signed in to Opogo</h2></div>
    <div class="col-12 like"></div>
  </div>
</div>