import { Params } from '@angular/router';
import dayjs from 'dayjs';

const Utils = {
  toServerTimeZone: (dateTime: any, timeZone: string) => {
    const clientOffset = dayjs.tz(timeZone).utcOffset();
    const appOffset = dayjs.tz(dayjs.tz.guess()).utcOffset();
    return dayjs(dateTime).utcOffset(appOffset - clientOffset);
  },
  toLocalTimeZone: (dateTime: any, timeZone: string) => {
    const clientOffset = dayjs.tz(timeZone).utcOffset();
    return dayjs(dateTime).utcOffset(clientOffset);
  },
  weekStart: (dateTime: any) => {
    return dayjs(dateTime).startOf('isoWeek').format('YYYY-MM-DD');
  },
  weekEnd: (dateTime: any) => {
    return dayjs(dateTime).endOf('isoWeek').format('YYYY-MM-DD');
  },
  nextWeekStart: (dateTime: any) => {
    return dayjs(dateTime)
          .endOf('isoWeek')
          .add(1, 'days')
          .set('hour', 8)
          .set('minute', 30)
          .set('second', 0)
          .format('YYYY-MM-DD');
  },
  previousWeekStart: (dateTime: any) => {
    return dayjs(dateTime)
          .startOf('isoWeek')
          .subtract(1, 'days')
          .startOf('isoWeek')
          .set('hour', 8)
          .set('minute', 30)
          .set('second', 0)
          .format('YYYY-MM-DD');
  },
  currentDateTime: () => {
    return dayjs();
  },
  toDateTime: (dateTime: string) => {
    return dayjs(dateTime);
  },
  dateBiggerThanToday: (dateTime: string) => {
    return dayjs(dateTime).date > dayjs().date;
  },
  sortDates: (t1, t2) => {
    if (t1.date > t2.date) {
      return 1;
    }
    if (t1.date < t2.date) {
      return -1;
    }
    return 0;
  },
  deepCopy(obj) {
    var copy;

    // Handle the 3 simple types, and null or undefined
    if (null == obj || 'object' != typeof obj) {
      return obj;
    }

    // Handle Date
    if (obj instanceof Date) {
      copy = new Date();
      copy.setTime(obj.getTime());
      return copy;
    }

    // Handle Array
    if (obj instanceof Array) {
      copy = [];
      for (var i = 0, len = obj.length; i < len; i++) {
        copy[i] = this.deepCopy(obj[i]);
      }
      return copy;
    }

    // Handle Object
    if (obj instanceof Object) {
      copy = {};
      for (var attr in obj) {
        if (obj.hasOwnProperty(attr)) {
          copy[attr] = this.deepCopy(obj[attr]);
        }
      }
      return copy;
    }

    throw new Error('Unable to copy obj! Its type isn\'t supported.');
  },
  // lowercasing keys in url but not the parameters
  paramsKeyToLower(params: Params): Params {
    const lowerParams: Params = {};
    for (const key in params) {
      lowerParams[key.toLowerCase()] = params[key];
    }

    return lowerParams;
  }


};


export function isDefined(value: any) {
  return value !== undefined && value !== null;
}

export function isObject(value: any) {
  return typeof value === 'object' && isDefined(value);
}

export function isPromise(value: any) {
  return value instanceof Promise;
}

export function isFunction(value: any) {
  return value instanceof Function;
}

export default Utils;
