<div class="month-picker-popup-container">
  <div class="month-picker-popup" [ngClass]="{'d-block': show}">
    <div class="row no-gutters header">
      <div class="col-auto prev-year" [ngClass]="{'inactive': selectedYear === firstYear}" (click)="prevYear()">
        <span class="material-icons" [attr.data-au]="'year-select-left'">chevron_left</span>
      </div>
      <div class="col text-center">{{selectedYear}}</div>
      <div class="col-auto next-year" [ngClass]="{'inactive': selectedYear === currentYear}" (click)="nextYear()">
        <span class="material-icons" [attr.data-au]="'year-select-right'">chevron_right</span>
      </div>
    </div>
    <div class="row no-gutters py-1">
      <div class="col text-center month" (click)="setMonth(0)" [ngClass]="{
        'selected': selectedMonth === 0}" [attr.data-au]="'month-select-jan'">Jan</div>
      <div class="col text-center month" (click)="setMonth(1)" [ngClass]="{
        'selected': selectedMonth === 1,
        'inactive': selectedYear === currentYear && currentMonth < 1}" [attr.data-au]="'month-select-feb'">Feb</div>
      <div class="col text-center month" (click)="setMonth(2)" [ngClass]="{
        'selected': selectedMonth === 2,
        'inactive': selectedYear === currentYear && currentMonth < 2}" [attr.data-au]="'month-select-mar'">Mar</div>
    </div>
    <div class="row no-gutters py-1">
      <div class="col text-center month" (click)="setMonth(3)" [ngClass]="{
        'selected': selectedMonth === 3,
        'inactive': selectedYear === currentYear && currentMonth < 3}" [attr.data-au]="'month-select-apr'">Apr</div>
      <div class="col text-center month" (click)="setMonth(4)" [ngClass]="{
        'selected': selectedMonth === 4,
        'inactive': selectedYear === currentYear && currentMonth < 4}" [attr.data-au]="'month-select-may'">May</div>
      <div class="col text-center month" (click)="setMonth(5)" [ngClass]="{
        'selected': selectedMonth === 5,
        'inactive': selectedYear === currentYear && currentMonth < 5}" [attr.data-au]="'month-select-jun'">Jun</div>
    </div>
    <div class="row no-gutters py-1">
      <div class="col text-center month" (click)="setMonth(6)" [ngClass]="{
        'selected': selectedMonth === 6,
        'inactive': selectedYear === currentYear && currentMonth < 6}" [attr.data-au]="'month-select-jul'">Jul</div>
      <div class="col text-center month" (click)="setMonth(7)" [ngClass]="{
        'selected': selectedMonth === 7,
        'inactive': selectedYear === currentYear && currentMonth < 7}" [attr.data-au]="'month-select-aug'">Aug</div>
      <div class="col text-center month" (click)="setMonth(8)" [ngClass]="{
        'selected': selectedMonth === 8,
        'inactive': selectedYear === currentYear && currentMonth < 8}" [attr.data-au]="'month-select-sep'">Sep</div>
    </div>
    <div class="row no-gutters py-1">
      <div class="col text-center month" (click)="setMonth(9)" [ngClass]="{
        'selected': selectedMonth === 9,
        'inactive': selectedYear === currentYear && currentMonth < 9}" [attr.data-au]="'month-select-oct'">Oct</div>
      <div class="col text-center month" (click)="setMonth(10)" [ngClass]="{
        'selected': selectedMonth === 10,
        'inactive': selectedYear === currentYear && currentMonth < 10}" [attr.data-au]="'month-select-nov'">Nov</div>
      <div class="col text-center month" (click)="setMonth(11)" [ngClass]="{
        'selected': selectedMonth === 11,
        'inactive': selectedYear === currentYear && currentMonth < 11}" [attr.data-au]="'month-select-dec'">Dec</div>
    </div>    
  </div>
</div>
