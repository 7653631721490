import { Injectable } from "@angular/core";
import dayjs from "dayjs";
import { Dayjs } from "dayjs";
import { DateFormatConstants } from '../utils/date';

@Injectable()
export class Term {
    Id: string = null;
    StartDate: Dayjs = null;
    EndDate: Dayjs = null;
    Year: string = null;
    Name: string = null;
    Term: string = null;
    Part: string = null;
    CurrentYear?: boolean = null;
    CurrentAcademicYear?: boolean = null;
    WorkingDaysCount?: number = null;
    _title: string = null;

    get Title() {
      if (!this._title) {
        this._title = `${this.Term} Term ${this.Part} ${this.Year}`;
      } 

      return this._title;
    }

    constructor() { }

    deserialize(input): any {
        if (typeof input === 'undefined' || input === null) {
          return this;
        }
    
        Object.keys(input)
          .forEach(key => {
            if (key === 'StartDate' || key === 'EndDate') {
              this[key] = dayjs(input[key], DateFormatConstants.YearMonthDayFormat);
            } else if (typeof input[key] !== 'undefined') {
              this[key] = input[key];
            }
          });
        return this;
      }
}

export interface TermClientOverride {
    TermId: string;
    StartDate: Date;
    EndDate: Date;
    OffsetTerms: boolean;
}