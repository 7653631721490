import { Component, OnInit } from '@angular/core';

import { HeaderService, DeviceService } from '@services';
import { AppTitle } from '@interfaces/header';

@Component({
  selector: 'app-forgot-password-email-confirmation',
  templateUrl: './forgot-password-email-confirmation.component.html',
  styleUrls: ['./forgot-password-email-confirmation.component.scss']
})
export class ForgotPasswordEmailConfirmationComponent implements OnInit {

  constructor(
    private headerService: HeaderService,
    public device: DeviceService
  ) {
  }

  ngOnInit() {
    this.headerService.changeHeader(<AppTitle>{
      title: '',
      subtitle: ''
    });
  }

}
