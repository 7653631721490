<div class="questionnaire row d-flex flex-column">
  <div class="col" [ngClass]="{'uncompleted': isNotValid && !reference.Role}">
    <label for="candidateRole">Position Candidate Worked</label>
    <input type="text" id="candidateRole" name="candidateRole" [(ngModel)]="reference.Role" [disabled]="disabled">
  </div>
  <div class="col" [ngClass]="{'uncompleted': isNotValid && !reference.FirstName}">
    <label for="refereeName">Name of individual providing letter of good conduct</label>
    <input type="text" id="refereeName" name="refereeName" [(ngModel)]="reference.FirstName" [disabled]="disabled">
  </div>
  <div class="col" [ngClass]="{'uncompleted': isNotValid && !reference.JobTitle}">
    <label for="refereeJobTitle">Position of individual providing letter of good conduct</label>
    <input type="text" id="refereeJobTitle" name="refereeJobTitle" [(ngModel)]="reference.JobTitle" [disabled]="disabled">
  </div>
  <div class="col" [ngClass]="{'uncompleted': isNotValid && !reference.OrganisationName}">
    <label for="refereeCompany">Company/Institute of individual providing letter of good conduct</label>
    <input type="text" id="refereeCompany" name="refereeCompany" [(ngModel)]="reference.OrganisationName" [disabled]="disabled">
  </div>
</div>

<!-- Confirmation -->
<div class="row confirm mx-0" *ngIf="!disabled">
  <div class="col-12">
      <h2 class="text-center">Confirm reference</h2>
      <p class="mb-4">
        I, <strong>{{ reference.FirstName }}</strong>, confirm that <strong>{{ reference.Candidate.FullName }}</strong> does not have a criminal record and that they have conducted themselves
        in a professional manner for the duration of their employment or education.
      </p>
      
      <div class="custom-control custom-checkbox mb-2">
          <input class="custom-control-input" id="accepted_01" type="checkbox" [(ngModel)]="reference.AgreeShareWithCandidate" [disabled]="disabled">
          <label class="custom-control-label" for="accepted_01">I am happy to share this reference with {{ reference.Candidate.FullName }} if they request.</label>
      </div>
          
      <div class="custom-control custom-checkbox">
          <input class="custom-control-input" id="accepted_02" type="checkbox" [(ngModel)]="reference.AgreeShareWithSchools" [disabled]="disabled">
          <label class="custom-control-label" for="accepted_02">I am happy to share this reference with other schools  /  institutes if requested.</label>
      </div>

      <p class="uncompleted text-center" [ngClass]="{'d-block': isNotValid }">Please answer the questions highlighted in red above</p>

      <p class="uncompleted text-center" [ngClass]="{'d-block': submissionError }">Something went wrong, please try again later.</p>

      <button class="btn btn-primary col-12 mb-3" (click)="updateReference()" name="ie11sux" value="Submit" >Confirm & submit <span [ngClass]="{'loader right': submitLoader}"></span></button>
      <p class="mb-0 text-center">Opogo trading under Thames Teachers</p>
  </div>
</div>

<div class="row logc-confirmation mx-0 px-0" *ngIf="disabled">
  <div class="col-10 px-0">
    <textarea [disabled]="disabled">I, {{ reference.FirstName }}, confirm that {{ reference.Candidate.FullName }} does not have a criminal record and that they have conducted themselves in a professional manner for the duration of their employment.
    </textarea>
  </div>
  <div class="col text-center align-self-center custom-control custom-radio">
    <input type="radio" id="logcConfirmation" name="logcConfirmation" class="custom-control-input" [disabled]="disabled" checked>
    <label class="custom-control-label" for="logcConfirmation"></label> 
  </div>
</div>

<div class="row confirm confirmation--statement mx-0" *ngIf="showConfirmationStatement()">
  <div class="col-12 text-center">
      <h2>Confirmation statement</h2>
      <p>This letter of good conduct was provided on <strong>{{ reference.CreatedOn | date : "dd MMMM yyyy" }}</strong> by <strong>{{ reference.FirstName }}</strong>.</p>
      <p class="mb-0 pb-0">Opogo trading under Thames Teachers</p>
  </div>
</div>