import { Injectable } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { BehaviorSubject } from 'rxjs';


export enum DeviceTypes {
  mobile = 0,
  tablet = 1,
  desktop = 2
}

@Injectable()
export class DeviceService {
  types = DeviceTypes;
  type: DeviceTypes = this.types.desktop;
  typeChange = new BehaviorSubject<DeviceTypes>(this.type);

  constructor(
    private deviceDS: DeviceDetectorService
  ) {
    this.detectDeviceType();
    window.addEventListener('resize', (event) => {
      this.detectDeviceType();
    });
  }

  get info() {
    return this.deviceDS.getDeviceInfo();
  }

  get mobile() {
    return this.type === DeviceTypes.mobile;
  }

  get tablet() {
    return this.type === DeviceTypes.tablet;
  }

  get desktop() {
    return this.type === DeviceTypes.desktop;
  }

  detectDeviceType() {
    if (this.deviceDS.isMobile() || window.innerWidth <= 576) {
      this.type = DeviceTypes.mobile;
    } else if (this.deviceDS.isTablet() || window.innerWidth <= 768) {
      this.type = DeviceTypes.tablet;
    } else {
      this.type = DeviceTypes.desktop;
    }
    this.typeChange.next(this.type);
    return this.type;
  }

  get device() {
    return this.type;
  }

  get browser() {
    return this.deviceDS.browser;
  }
}
