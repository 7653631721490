import { Injectable, EventEmitter } from '@angular/core';
import { environment } from '../../environments/environment';

// declare the global variables  
declare const $: any;

@Injectable()
export class SignalRService {
  private connectionId;
  private authData;
  private signalRConnectionId;
  private proxy: any;
  private connection: any;
  private tryingToReconnect = false;
  public CandidateEvent: EventEmitter<any>;

  constructor() {
    this.CandidateEvent = new EventEmitter<any>();
  }

  public initialize(): void {
    this.connection = $.hubConnection(environment.signalRBaseURL);
    this.proxy = this.connection.createHubProxy('JobNotificationHub');
    //this.setToken();

    this.proxy.on('CandidateEvent', (jobId, candidateId, status) => {
      this.CandidateEvent.emit({JobId: jobId, CandidateId: candidateId, Status: status});
    });

    this.proxy.on('broadcastTimeOut', () => {
      this.initialize();
    });

    this.stopConnection();

    this.connection.start().done((data: any) => {
      this.connectionId = this.connection.id;
      console.log('Now connected, connection ID=' + this.connection.id);
    }).fail((error: any) => {
      console.log('Could not connect ' + error);
    });

    this.connection.reconnecting(() => {
      this.tryingToReconnect = true;
    });

    this.connection.reconnected(() => {
      this.tryingToReconnect = false;
    });

    this.connection.error((error) => {
      this.initialize();
    });

    this.connection.disconnected(() => {
      if (this.tryingToReconnect) {
        setTimeout(() => {
          this.initialize();
        }, 5000);

      }
    });
  };

  setToken() {
    this.authData = window.localStorage.getItem('authorizationData');
    if (this.authData) {
      const token = this.authData.token;
      $.signalR.ajaxDefaults.headers = {Authorization: 'Bearer ' + token};
    }
  };

  stopConnection() {
    this.connection.stop();
  };

  getConnection() {
    return this.connectionId;
  };
}
