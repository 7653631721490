import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { FeatureFlagsService, FEATURE_FLAG } from '@app/interfaces'

@Injectable({
  providedIn: 'root'
})
export class LocalizedFeatureFlagsService implements FeatureFlagsService {
  FEATURE_FLAGS: Record<FEATURE_FLAG, boolean>;

  constructor(feature_flags: Record<FEATURE_FLAG, boolean>) {
    this.FEATURE_FLAGS = feature_flags;
  }

  public getFeatureFlag(featureFlag: FEATURE_FLAG): Promise<boolean> {
    return Promise.resolve(this.FEATURE_FLAGS[featureFlag]);
  }
}
