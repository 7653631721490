import { AfterViewInit, Component, ElementRef, Input, OnInit } from '@angular/core';
import $ from 'jquery';
import { DeviceService } from '@services/device.service';

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss']
})
export class TooltipComponent implements OnInit, AfterViewInit {
  @Input() text = '';
  @Input() visibility = false;
  offset = 0;


  constructor(
    private eRef: ElementRef,
    public device: DeviceService
  ) {
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.device.mobile && this.eRef.nativeElement.children.length) {
        this.setPosition();
      }
    });
  }

  setPosition() {
    const element = this.eRef.nativeElement.children[0];

    const offset = $(element).offset().left;
    if (offset < 20) {
      this.offset = -offset + 20;
      element.style.setProperty('--pos-r', this.offset + 'px');
      element.style.setProperty('--pos-l', '0px');
    } else if (window.innerWidth - offset - element.clientWidth < 20) {
      this.offset = -(window.innerWidth - offset - element.clientWidth) + 20;
      element.style.setProperty('--pos-l', this.offset + 'px');
      element.style.setProperty('--pos-r', '0px');
    }
  }

  toggle() {
    this.visibility ? this.hide() : this.show();
  }

  show() {
    this.visibility = true;
  }

  hide() {
    this.visibility = false;
  }

}
