import { 
  Directive, 
  Inject, 
  Input, 
  OnChanges, 
  OnInit, 
  SimpleChanges, 
  TemplateRef, 
  ViewContainerRef, 
  inject 
} from '@angular/core';
import { FEATURE_FLAGS_SERVICE } from '@app/interfaces/feature';
import { FEATURE_FLAG } from '@app/interfaces';
import { LocalizedFeatureFlagsService } from '../localization/services/localized-feature-flags.service';

@Directive({
  selector: '[ifFeatureFlag]',
  standalone: true
})
export class FeatureFlagDirective implements OnInit {
  @Input() public ifFeatureFlag: FEATURE_FLAG;
  @Input() public ifFeatureFlagPermission: string;

  private templateRef = inject(TemplateRef<unknown>);
  private viewContainerRef = inject(ViewContainerRef);

  constructor(@Inject(FEATURE_FLAGS_SERVICE) private featureFlagService: LocalizedFeatureFlagsService) {}

  async ngOnInit() {
    await this.evaluateFeatureDisplay();
  }

  async evaluateFeatureDisplay() {
    const generalFeatureFlag = await this.featureFlagService.getFeatureFlag(this.ifFeatureFlag);
      
    if (generalFeatureFlag) {
      this.onIf();
    }
    else {
      this.viewContainerRef.clear();
    }
  }

  private onIf(): void {
    this.createView(this.templateRef);
  }

  private createView(templateRef: TemplateRef<unknown>): void {
    this.viewContainerRef.createEmbeddedView(templateRef);
  }
}
