import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { SharedModule } from '../../shared.module';
import { ChatpopupComponent } from './components/chatpopup/chatpopup.component';
import { CommonControlsModule } from '../commoncontrols/commoncontrols.module';
import { LastMessageDatePipe } from './pipes/last-message-date.pipe';
import { ChatroomsComponent } from './components/chatpopup/chatrooms.component';
import { ChatMessagesComponent } from './components/chat-messages/chat-messages.component';
import { MessageTimePipe } from './pipes/message-time.pipe';
import { LocalizationModule } from '@app/localization/localization.module';
import { LocalizeDatePipe } from '@app/localization/pipes/localize-date.pipe';

@NgModule({
    imports: [
      CommonModule,
      FormsModule,
      SharedModule,
      CommonControlsModule,
      LocalizationModule
    ],
    declarations: [
      ChatpopupComponent,
      LastMessageDatePipe,
      ChatroomsComponent,
      ChatMessagesComponent,
      MessageTimePipe
    ], 
    exports: [
      ChatpopupComponent
    ],
    providers: [
      LocalizeDatePipe
    ]
  })
  export class ChatModule { }