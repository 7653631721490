<div class="text-center sign-block row" device>
  <div class="col-6 form-wrap">
    <div class="logo-container">
      <img src="assets/img/opogo_logo.png">
    </div>

    <form novalidate class="sign-form">
      <h1 class="form-head">Sorry</h1>
      <p >You haven't been set up on Opogo Talent</p>


        <button class="btn btn-primary"  (click)="goToSignIn()">Sign in to the Community</button>
    </form>

    <div class="outside-footer">
      <div class="footer-label">
        Create new school account
        <a class="footer-link" href="{{opogoService.schoolSignUpUrl}}">sign up</a>
      </div>
    </div>
  </div>

  <div class="col-6" *ngIf="!device.mobile">
    <app-outside-img-container></app-outside-img-container>
  </div>

</div>
