import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ClientDataStoreService, ClientService, DeviceService, JobService } from '../../../_shared';
import { Term } from './../../../../interfaces';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Location } from '@angular/common';
import { RouteAnchor } from '@app/models/route-anchor.model';
import { Subscription } from 'rxjs';
import { Label } from '@app/localization/providers/localized-text.provider';

export enum BookSupplyType {
  AllCandidates = 0,
  TalentPool = 1,
  BankStaff = 2
}

@Component({
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HomeComponent implements OnInit, OnDestroy {
  BookSupplyTab = BookSupplyType;
  localizedLabel = Label;
  childRoutes: string[] = ['/buildajob', '/talentpool', '/bankstaff'];
  analytics = getAnalytics();
  bookingTerms: Term[];
  subRoutes: RouteAnchor[];
  candidateCount: number = null;
  talentPoolCount: number = null;
  bankStaffCount: number = null;
  refreshTalentPool: boolean = false;
  refreshAllCandidates: boolean = false;
  skipUrlFragmentHandling: Boolean = false;

  selectedTab: BookSupplyType;

  routerEventsSubscription: Subscription;

  constructor(
    private jobService: JobService,
    private router: Router,
    private location: Location,
    public device: DeviceService,
    private activatedRoute: ActivatedRoute,
    private clientStore: ClientDataStoreService,
    private clientService: ClientService,
    private change: ChangeDetectorRef,
  ) {
    this.activatedRoute.url.subscribe(url => {
      this.selectedTab = this.jobService.bookSupplyTypeTab;

      if (url !== null && url.length > 0 && url[0].path === 'buildajob') {
        this.selectedTab = BookSupplyType.AllCandidates;
        this.jobService.bookSupplyTypeTab = BookSupplyType.AllCandidates;
      }
      else if (url !== null && url.length > 0 && url[0].path === 'talentpool') {
        this.selectedTab = BookSupplyType.TalentPool;
        this.jobService.bookSupplyTypeTab = BookSupplyType.TalentPool;
      } 
      else if (url !== null && url.length > 0 && url[0].path === 'bankstaff') {
        this.selectedTab = BookSupplyType.BankStaff;
        this.jobService.bookSupplyTypeTab = BookSupplyType.BankStaff;
      }
      else {
        this.navigateByBookSupplyType(this.selectedTab);
      }
    });

    this.clientStore.terms$.subscribe(terms => {
      this.bookingTerms = terms;
    })
  }

  ngOnInit() {
    this.routerEventsSubscription = this.router.events
      .subscribe(
        (event) => {
          if (event instanceof NavigationStart) {
            this.skipUrlFragmentHandling = new Boolean(true);
          }

          if (event instanceof NavigationEnd && this.childRoutes.some(r => event.url.indexOf(r) >= 0)) {
            this.skipUrlFragmentHandling = new Boolean(false);
            this.navigateByBookSupplyType(this.selectedTab);
            this.change.detectChanges();
          }
        }
      );
  }

  changeCandidateCount(count: number, supplyTypeTab: BookSupplyType) {
    switch (supplyTypeTab) {
      case BookSupplyType.AllCandidates:
        this.candidateCount = count;
        break;
      case BookSupplyType.TalentPool:
        this.talentPoolCount = count;
        break;
      case BookSupplyType.BankStaff:
        this.bankStaffCount = count;
        break;
    }
  }

  increaseTalentPoolCount(number: number) {
    this.refreshTalentPool = !this.refreshTalentPool;
  }

  refreshAllCandidatesList() {
    this.refreshAllCandidates = !this.refreshAllCandidates;
  }

  setBookSupplyType(supplyTypeTab: BookSupplyType) {

    if (supplyTypeTab !== this.selectedTab) {
      this.selectedTab = supplyTypeTab;
      this.jobService.bookSupplyTypeTab = supplyTypeTab;
      this.navigateByBookSupplyType(supplyTypeTab);
    }

    if (supplyTypeTab == BookSupplyType.AllCandidates) {
      logEvent(this.analytics, "click_booksuply_all_tab");
    } else if (supplyTypeTab == BookSupplyType.TalentPool) {
      logEvent(this.analytics, "click_booksuply_talentpool_tab");
    } else {
      logEvent(this.analytics, "click_booksuply_bankstaff_tab");
    }
  }

  navigateToLongTerm() {
    this.router.navigate(['add-job/long-term']);
  }

  navigateByBookSupplyType(bookSupplyType: BookSupplyType) {
    var url = new URL(window.location.href);
    switch(bookSupplyType) {
      case BookSupplyType.AllCandidates:
        this.location.replaceState(`add-job/buildajob`)
        break;
      case BookSupplyType.TalentPool:
        this.location.replaceState(`add-job/talentpool`)
        break;
      case BookSupplyType.BankStaff:
        this.location.replaceState(`add-job/bankstaff`)
        break;
    }
  }

  navigateUrlFragmentIfAny() {
    this.activatedRoute.fragment.subscribe(fragment => {
      if (!fragment) { 
        return;
      }
      
      let subRoute = this.subRoutes.find(r => r.name.toLowerCase() === fragment.toLowerCase() 
        || r.subroutes.some(sr => sr.toLowerCase() === fragment.toLowerCase()));

      if (subRoute)
      {
        subRoute.navigate();
      }
    })
  }

  logPostLongTermRole() {
    logEvent(this.analytics, "click_booksuply_post_longterm_role");
  }

  ngOnDestroy(): void {
    this.routerEventsSubscription?.unsubscribe();
  }

}