import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, filter, catchError } from 'rxjs/operators';
import { ClientDataStoreService } from '@app/services/client-data-store.service';

@Injectable()
export class UserInfoResolver {

  constructor(private router: Router, private clientStore: ClientDataStoreService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return this.clientStore.clientSummary$.pipe(
      filter(result => !!result),
      map(result => result),
      catchError(error => {
        console.log(`Whoops, we're unable to resolve the client summary. Might want to look into that! Here's the error message: ${error}`);
        
        return of(null);
      }));
  }
}