export interface ChatRoom {
  LastMessage:	string;
  LastMessageDate: Date;
  HistoryDeletedDate?: number;
  ChannelId:	string;
  UnreadMessages:	number;
  ChatType:	ChatCandidateContactType;
  Participants:	ChatContact[];
  IsExistingContact:	boolean;
  HistoryChannelIds?:	string[];
  SmsCommunication:	boolean;
}

export enum ChatCandidateContactType {
  Consultant = 0,
  Client = 1,
  Candidate	= 2,
  Group	= 3
}

export interface ChatContact {
  ContactUserId:	string;
  ProfileImageUrl:	string;
  ContactName:	string;
  ContactInitials?:	string;
  ContactFirstName:	string;
  ContactLastName:	string;
  ContactId:	string;
  Role?:	string;
  ClientName:	string;
}

export interface ChatChannel {
  ChannelId: string;
}