import { Injectable } from '@angular/core';
import { Review } from '@app/interfaces';
import { Observable } from 'rxjs';
import { HttpService } from './http.service';

@Injectable()
export class ReviewService {

  constructor(private http: HttpService) {
  }

  reviewCandidate(review: Review): Observable<any> {
    let url: string = `/clients/review`;
    return this.http.post(url, review);
  }

}
