<div class="row no-gutters candidate-tabs px-5 pt-4" [attr.data-au]="'tabs'">
  <div class="col-auto candidate-tab px-4 pb-2" [ngClass]="{'selected': selectedTab === CandidateTab.About}"
       (click)="setTab(CandidateTab.About)" [attr.data-au]="'about-tab-btn'">
    <div [attr.data-au]="'about-tab-title'">About</div>
  </div>
  <div class="col-auto candidate-tab px-4 pb-2" [ngClass]="{'selected': selectedTab === CandidateTab.Courses}"
       *ngIf='candidate && candidate.Courses && candidate.Courses.length > 0'
       (click)="setTab(CandidateTab.Courses)" [attr.data-au]="'courses-tab-btn'">
    <div [attr.data-au]="'courses-tab-title'">Learn CPD ({{candidate.Courses.length}})</div>
  </div>
  <div class="col-auto candidate-tab px-4 pb-2" [ngClass]="{'selected': selectedTab === CandidateTab.Reviews}"
       *ngIf='candidate && candidate.Reviews && candidate.Reviews.TotalReviews > 0'
       (click)="setTab(CandidateTab.Reviews)" [attr.data-au]="'reviews-tab-btn'">
    <div [attr.data-au]="'reviews-tab-title'">Reviews ({{candidate.Reviews.TotalReviews}})</div>
  </div>
  <div class="col-auto disabled-candidate-tab px-4 pb-2" [attr.data-au]="'reviews-tab-btn'"
       *ngIf='candidate && (!candidate.Reviews || candidate.Reviews.TotalReviews === 0)'>
    <div [attr.data-au]="'reviews-tab-title'">[No reviews yet]</div>
  </div>
  <div class='col pl-2 text-right'>
    <div class='close-modal px-2' (click)="close()" [attr.data-au]="'close-modal-btn'">X</div>
  </div>
</div>

<div class="row no-gutters loader blue center" *ngIf="showLoader">
</div>

<div *ngIf='candidate && selectedTab === CandidateTab.About' [attr.data-au]="'about-tab'">
  <div class="row no-gutters general-information px-4" [attr.data-au]="'general-info'">
    <div class='col-auto py-4'>
      <div class="square user-profile-img op-avatar" [attr.data-au]="'avatar'">
        <img *ngIf="candidate.Photo" [src]="candidate.Photo" class="content" [attr.data-au]="'profile-img'">
      </div>
    </div>
    <div class='col'>
      <div class="row no-gutters pl-4 pt-4">
        <div class="col">
          <span class="candidate-name" [attr.data-au]="'name'" 
            [ngClass]="{
              'in-talent-pool' : !candidate.IsBankStaff && candidate.InTalentPool,
              'in-bank-staff': candidate.IsBankStaff
            }">{{candidate.FirstName}} {{ candidate.TotalDaysAtClient ? candidate.LastName : '' }}</span>
          <div class="bank-staff-badge" *ngIf="candidate.IsBankStaff; else ProfileStars">
            <span>{{ LocalizedLabel.BankStaffBadge | localizeText }}</span>
          </div>
          <ng-template #ProfileStars>
            <ng-container *ngIf="candidate.InTalentPool"><i [attr.data-au]="'star'" class="m-icon ro va-m star px-2">star</i></ng-container>
          </ng-template>
        </div>
      </div>
      <div class="row no-gutters pl-4">
        <div class="col-sm-12">
          <span class="candidate-role primary" [attr.data-au]="'role'">{{primaryProfile.Role}}</span>
        </div>
        <div class="col-sm mt-2">
          <span *ngIf="candidate.Profiles.length > 1" class="show-additional-roles" (click)="toggleAdditionalProfilesVisibility()" [attr.data-au]="'additional-profiles-toggle'">
            <span *ngIf="!showAdditionalProfiles">View +{{candidate.Profiles.length - 1}}</span>
            <span *ngIf="showAdditionalProfiles">Hide</span>
            <span> additional roles</span>
          </span>
        </div>
      </div>

      <div *ngIf="showAdditionalProfiles" [attr.data-au]="'profiles'">
        <div class="row no-gutters pl-4">
          <div class="col secondary-roles-table">
            <ng-container *ngFor="let profile of candidate.Profiles">
              <div class="candidate-role" [attr.data-au]="'role'" *ngIf="!profile.IsPrimary">
                {{profile.Role}}
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-3 d-flex justify-content-end align-items-start additional-details">
      <div class="row mx-0 pt-4">
        <div *ngIf="candidate.Reviews.AverageScore > 3" class="col text-right pr-1 py-1" [attr.data-au]="'review'">
          <span class="candidate-review-caption" [attr.data-au]="'review-caption'">{{candidate.Reviews.AverageScore | reviewCaption}}</span>
          <span class="candidate-review-score ml-2" [attr.data-au]="'review-score'">{{candidate.Reviews.AverageScore | number:'1.0-1'}}</span>
        </div>
        <div class="col-sm-12 px-0 py-1">
          <div class="compliance p-2">
            <span class="material-icons">check_circle</span><strong class="compliance-percentage px-1"> 100% Compliant</strong>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="candidate-details no-gutters mx-4 mt-3" [attr.data-au]="'statistics'">
    <div class="details-header px-4 py-3" *ngIf="candidate.TotalDays > 0"
      [ngClass]="{ 'border-bottom': !(candidate.About && candidate.About !== '') && !candidate.TagCategories.length }">
      <span>{{ candidate.FirstName }} has completed <strong>{{ candidate.TotalDays > 1 ? candidate.TotalDays + ' days' : candidate.TotalDays + ' day' }} of work with Opogo</strong></span>
      <span *ngIf="candidate.TotalDaysAtClient > 0"> and worked <strong>{{ candidate.TotalDaysAtClient > 1 ? candidate.TotalDaysAtClient + ' days' : candidate.TotalDaysAtClient + ' day'}} at your school</strong></span>
    </div>
    <div class="details-body" *ngIf="(candidate.About && candidate.About !== '') || candidate.TagCategories.length > 0"
      [ngClass]="{ 'top-border': !candidate.TotalDays }">
      <ng-container *ifFeatureFlag="FEATURE_FLAG.CANDIDATE_SUMMARY">
        <div class="about-section" *ngIf="candidate.About && candidate.About !== ''">
          <div [attr.data-au]="'about-summary'">
            <div class="row about-text">
              <div class="col-12 pl-6" [attr.data-au]="'about-text'" innerHTML="{{ candidate.About.toHtml() }}"></div>
            </div>
          </div>
        </div>
        <hr class="about-section-separator mt-0" *ngIf="candidate.TagCategories.length > 0">
      </ng-container>
      <div class="tag-categories px-4 pb-4 row mx-0" *ngIf="candidate.TagCategories.length > 0">
        <div class="col-6 px-0">
          <div class="tag-category" *ngFor="let tagCategory of leftColumnTagCategories">
            <strong class="category-name">{{ tagCategory.Description ?? tagCategory.Name }}</strong>
            <div class="row mx-0 mt-2">
              <div class="tag" *ngFor="let tag of tagCategory.Tags">
                <span>{{ tag.Name }}</span>
              </div>
              <div class="tag cpd-tag" *ngIf="tagCategory.Id === qualificationsCategoryId && candidate.Courses.length > 0">
                <span (click)="setTab(CandidateTab.Courses)">Learn CPD ({{candidate.Courses.length}})</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-6 px-0">
          <div class="tag-category" *ngFor="let tagCategory of rightColumnTagCategories">
            <strong class="category-name">{{ tagCategory.Description ?? tagCategory.Name }}</strong>
            <div class="row mx-0 mt-2">
              <div class="tag" *ngFor="let tag of tagCategory.Tags">
                <span>{{ tag.Name }}</span>
              </div>
              <div class="tag cpd-tag" *ngIf="tagCategory.Id === qualificationsCategoryId && candidate.Courses.length > 0">
                <span (click)="setTab(CandidateTab.Courses)">Learn CPD ({{candidate.Courses.length}})</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf='candidate && selectedTab === CandidateTab.Courses' class="courses-tab" [attr.data-au]="'courses-tab'">
  <div class="header">
    <div>{{candidate.FirstName}}</div>
    Courses completed
  </div>
  <div class="course-list">
    <div *ngFor="let course of candidate.Courses" class="course-item">
      <div [innerHTML]="course.CourseTitle"></div>
      <div class="course-label">{{course.CourseCompletedAt | date: 'd MMM'}} &nbsp;|&nbsp; {{course.Platform}}</div>
    </div>
  </div>
</div>

<div *ngIf='candidate && selectedTab === CandidateTab.Reviews' class="reviews-tab" [attr.data-au]="'reviews-tab'">

  <div class="row no-gutters reviews-score px-5 py-4" [attr.data-au]="'review'">
    <div *ngIf='candidate.Reviews.AverageScore' class="col-auto pt-2">
      <div class="review-score" [attr.data-au]="'review-score'">{{candidate.Reviews.AverageScore | number:'1.0-1'}}</div>
    </div>
    <div class="col-auto px-3 reviewed-candidate" [attr.data-au]="'reviewed-candidate'">
      <div>
        <span class="candidate-name" [attr.data-au]="'name'" 
          [ngClass]="{
            'in-talent-pool' : candidate.InTalentPool && !candidate.IsBankStaff,
            'in-bank-staff': candidate.IsBankStaff
         }">
          {{candidate.FirstName}} {{ candidate.TotalDaysAtClient ? candidate.LastName : '' }}</span>
          <i *ngIf="candidate.InTalentPool && !candidate.IsBankStaff" [attr.data-au]="'star'" class="m-icon ro va-m star px-2">star</i>    
      </div>
      <div class="review-caption" [attr.data-au]="'review-caption'">{{candidate.Reviews.AverageScore | reviewCaption}}</div>
    </div>
    <div class="col text-right" [attr.data-au]="'score-types'">
      <div class="rate-type">Professionalism</div>
      <div class="rate-type">Communication</div>
      <div class="rate-type">Punctuality</div>      
    </div>
    <div class="col-auto" [attr.data-au]="'scores'">
      <div class="stars pb-2 pl-2" [attr.data-au]="'professionalism-score'" [style]="getScoreStyle(candidate.Reviews.ProfessionalismScore)"></div>
      <div class="stars pb-2 pl-2" [attr.data-au]="'communication-score'" [style]="getScoreStyle(candidate.Reviews.CommunicationScore)"></div>
      <div class="stars pb-2 pl-2" [attr.data-au]="'punctuality-score'" [style]="getScoreStyle(candidate.Reviews.PunctualityScore)"></div>
    </div>
  </div>

  <div *ngIf="candidate.Reviews.TopTestimonials && candidate.Reviews.TopTestimonials.length > 0"
    class="top-reviews px-5 py-4" [attr.data-au]="'top-testimonials'">
    
    <div class="row reviews-title pb-3">
      <div class="col" [attr.data-au]="'reviews-title'">School reviews ({{candidate.Reviews.TotalReviews}})</div>
    </div>

    <div *ngFor="let testimonial of candidate.Reviews.TopTestimonials" class="row no-gutters py-3" [attr.data-au]="'testimonial'">
      <div class="col-auto pr-3">
        <div class="square user-profile-img op-avatar" [attr.data-au]="'reviewer-avatar'">
          <img *ngIf="testimonial.ReviewerPhotoURL" [src]="testimonial.ReviewerPhotoURL" class="content" [attr.data-au]="'reviewer-profile-img'">
        </div>        
      </div>
      <div class="col-2hd px-2 reviewer-details" [attr.data-au]="'reviewer-details'">
        <div class="reviewer-name" [attr.data-au]="'reviewer-name'">{{testimonial.ReviewerName}}</div>
        <div class="reviewer-job" [attr.data-au]="'reviewer-job-title'">{{testimonial.ReviewerJobTitle}}</div>
        <div class="client-name" [attr.data-au]="'reviewer-client'">{{testimonial.ClientName}}</div>
      </div>
      <div class="col px-3 review-text" [attr.data-au]="'testimonial-text'">{{testimonial.Testimonial}}</div>
      <div class="col-auto ml-3 candidate-review-score" [attr.data-au]="'testimonial-score'">{{testimonial.Score |  number:'1.0-1'}}</div>
    </div>

  </div>

</div>

<div *ngIf='candidate' class="row no-gutters pt-4 justify-content-center">
  <div class="col-auto pt-1">
    <div class="full-profile-btn py-2 px-4" (click)="goToTalentProfile()" [attr.data-au]="'view-profile-btn'">View full profile</div>
  </div>
</div>



