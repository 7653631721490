import { Pipe, PipeTransform } from '@angular/core';
import dayjs from 'dayjs';

@Pipe({
  name: 'bookingDays'
})
export class BookingDaysPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (value) {
      value = value.sort((t1, t2) => {
        if (t1.Date > t2.Date) {
          return 1;
        }
        if (t1.Date < t2.Date) {
          return -1;
        }
        return 0;
      });
      return value.length > 0 ? `${dayjs.utc(value[0].Date).format('D MMM')}` + (value.length > 1 ? ` - ${dayjs.utc(value[value.length - 1].Date).format('D MMM')}` : '') : '';
    }
    return '';
  }

}
