import { Injectable, Inject } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { FEATURE_FLAGS_SERVICE } from '@app/interfaces';
import { LocalizedFeatureFlagsService } from '../localization/services/localized-feature-flags.service';

@Injectable({
  providedIn: 'root'
})
export class FeatureFlagGuard implements CanActivate {
  
  constructor(@Inject(FEATURE_FLAGS_SERVICE) private featureFlagService: LocalizedFeatureFlagsService, private router: Router) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    let canActivateFeature =  await this.featureFlagService.getFeatureFlag(route.data.featureFlag);

    if (!canActivateFeature) {
      this.router.navigate(['*']);
    } 
    
    return canActivateFeature;
  }
}