import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';

import { CandidateService, AuthService } from '../services';

@Injectable()
export class ExpertsResolver  implements OnDestroy {
  experts: any;
  logOutEventSubscription: any;

  constructor(private auth: AuthService, private candidateService: CandidateService) {
    this.logOutEventSubscription = this.auth.LogOutEvent.subscribe(() => {
      this.experts = null;
    });
  }

  ngOnDestroy() {
    this.logOutEventSubscription.unsubscribe();
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    if (!this.experts) {
      this.candidateService.getExperts().subscribe(
        result => {
          if (result) {
            this.experts = result;
            this.experts.forEach(expert => {
              expert.Name = `${expert.FirstName.trim()} ${expert.LastName.trim()}`;
            });
          }
          // console.log('Experts:', this.experts);
        },
        error => {
          console.log(error);
        }
      );
    }

    return of(this.experts);
  }
}
