import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { AuthService, ExpiresKey } from '../services/auth.service';
import { environment } from '../../environments/environment';

@Injectable()
export class AuthGuard  {
  constructor(
    private router: Router,
    private auth: AuthService
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.authGuard(route);
  }

  canActivateChild(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.authGuard(route);
  }

  authGuard(route: ActivatedRouteSnapshot): Observable<boolean> {
    if (this.auth.expires === null) {
      this.auth.tryLogoutMoEngage();
      this.auth.ThirdPartyServicesAuthenticated = false;

      localStorage.setItem('redirectedFromUrl', window.location.pathname + window.location.search);

      this.router.navigate(['/login']);

      return of(false);
    }

    const tokenExpiration: Date = new Date(this.auth.expires);
    const now: Date = new Date();
    const timeDeviation: number = environment.loginExprirationDeviation;
    const deviatedTokenExpiration = new Date(tokenExpiration.valueOf() - timeDeviation);

    if (this.auth.token !== null && this.auth.refToken !== null) {
      if (now < tokenExpiration) {
        if (now > deviatedTokenExpiration) {
          return this.auth.refreshToken(this.auth.refToken).pipe(
            map((response) => {
              const responseJson = response.json();

              this.auth.pipeLogin(responseJson.access_token, responseJson.refresh_token, responseJson['.expires']);

              return true;
            }),
            catchError(() => {
              this.auth.logout();
              this.router.navigate(['/login']);

              return of(false);
            })
          );
        }

        return of(true);
      }
    }

    this.auth.logout();
    this.router.navigate(['/login']);

    return of(false);
  }
}
