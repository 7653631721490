import { ElementRef } from "@angular/core";

export enum DialogResult {
  Cancel = 0,
  Confirm = 1
}
  
export type ConfirmDialogConfig = {
  title: string,
  description: string,
  closeButtonText: string,
  confirmButtonText: string,
  styleClass: string
}

export interface DialogConfig {
  Title: string;
  Description: string;
  HighlightElement: ElementRef;
  PositionRelativeToElement: string;
  Width: number;
  Height: number;
  CssClass: string;
  Mobile: boolean;
}