import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { OpogoService } from '@app/services';

export enum ErrorType {
  PageNotFound,
  CreateJobFailed
}

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss']
})
export class ErrorPageComponent implements OnInit {
  error: ErrorType;
  consultantInfo: any;

  get ErrorType() {
    return ErrorType;
  }

  constructor(
    public opogoService: OpogoService,
    private activatedRoute: ActivatedRoute) {
  }

  ngOnInit() {
    this.activatedRoute.paramMap
      .pipe(map(() => window.history.state))
      .subscribe(state => {
        this.error = state && state.error;
        this.consultantInfo = state && state.consultantInfo;
      }
    );
  }

}
