import { Injectable } from '@angular/core';
import { environment } from "../../environments/environment";

@Injectable()
export class OpogoService {

    appUrl: string = environment.appUrl;
    websiteUrl: string = environment.websiteUrl;

    signUpUrl: string = `${environment.production ? environment.websiteUrl : ''}/signup`;
    signInUrl: string = `/login`;

    schoolSignUpUrl: string = `${environment.schoolSignUpUrl}`;

    termsUrl: string = `${environment.websiteUrl}/terms`;
    privacyUrl: string = `${environment.websiteUrl}/privacy`;

    servicesEmail: string = environment.servicesEmail;
}