import { Component } from '@angular/core';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';

@Component({
  selector: 'app-loading-screen',
  standalone: true,
  imports: [
    MatProgressSpinnerModule
  ],
  templateUrl: './app-loading-screen.component.html',
  styleUrl: './app-loading-screen.component.scss'
})
export class AppLoadingScreenComponent {

}
